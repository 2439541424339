* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
  font-size: $font__size;
  line-height: $font__line-height;
  font-family: $font__family;
  @include font-face-regular;
  color: $text__color;
}

html {
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
  min-width: 300px;
  overflow-x: auto;
  background-color: $white;
}

.page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  background-color: $white;
  padding-top: $header__height-ph;

  @include media-breakpoint-up(tb) {
    padding-top: $header__height
  }
}

.main {
  display: block;
  flex-grow: 1;
  flex-basis: 0;
}

.container {
  width: 100%;
  max-width: ($container__width_max + ($container__padding_lt * 2));
  padding: 0 $container__padding;
  margin: 0 auto;

  @include media-breakpoint-up(tb) {
    padding: 0 $container__padding_tb;
  }

  @include media-breakpoint-up(tbx) {
    padding: 0 $container__padding_tbx;
  }

  @include media-breakpoint-up(lt) {
    padding: 0 $container__padding_lt;
  }
}

.section {
  position: relative;
  padding: 60px 0;

  @include media-breakpoint-down(phx) {
    padding-top: 45px;
    padding-bottom: 45px;
  }

  .container {
    position: relative;
    z-index: 2;
  }

  &.bg-success {
    color: $white;
  }

  &_bg-image {
    position: relative;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: $white;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: block;
      background: rgba(0,0,0,.6);
    }

    > .container {
      position: relative;
    }
  }

  &_bg-half {
    background: $bg__block;

    @include media-breakpoint-up(lt) {
      padding-top: 75px;
      padding-bottom: 40px;
      background: transparent;
    }
  }

  &--about-descr {

    .h2 {
      margin-bottom: .32em;
    }

    .btn-wrap {
      margin-top: 25px;

      .btn {
        margin-right: 11px;
        margin-bottom: 10px;
      }
    }
  }

  &--profit {
    padding-bottom: 30px;
  }

  &--tokens {
    padding-top: 80px;
    padding-bottom: 20px;

    .col-pic {
      flex: 0 0 39.2%;
      max-width: 39.2%;
      padding-right: 36px;

      @include media-breakpoint-down(tb) {
        flex: 0 0 100%;
        max-width: 100%;
        padding-right: 10px;
      }
    }

    .col-text {
      flex: 0 0 60.8%;
      max-width: 60.8%;

      @include media-breakpoint-down(tb) {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }

  &--how-to-buy {
    padding-top: 20px;
    padding-bottom: 50px;
  }

  &--faq {
    padding-top: 50px;
  }

  &--btn {
    padding-top: 35px;
    padding-bottom: 25px;

    @include media-breakpoint-down(tb) {
      padding-top: 35px;
      padding-bottom: 0;
    }

    @include media-breakpoint-down(phx) {
      padding-top: 35px;
      padding-bottom: 0;
    }

    .container {
      text-align: center;
    }

    .btn {
      margin-right: 15px;
      margin-bottom: 10px;
    }
  }
}

.section-bg-left {
  position: relative;
  margin-bottom: 30px;

  * {
    position: relative;
    z-index: 2;
  }

  @include media-breakpoint-up(lt) {
    margin-bottom: 0;

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: -52px;
      left: -5000px;
      right: 0;
      bottom: -40px;
      background: $bg__block;
    }

    &_lg {
      &::before {
        right: 40px;
      }
    }
  }
}

.section-head {
  margin-bottom: 1.5rem;

  .h2 {
    margin: 0;
  }

  .link {
    display: inline-block;
    margin-top: 10px;
  }

  @include media-breakpoint-up(tb) {
    display: flex;
    align-items: baseline;
    margin-bottom: 1rem;

    .link {
      flex-shrink: 0;
      white-space: nowrap;
      margin-left: 30px;
      margin-top: 0;
    }
  }
}

a {
  color: $primary;
  transition: color .2s;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    color: darken($primary, 10%);
    outline: none;
  }

  &[href^='tel:'] {
    white-space: nowrap;
  }
}

p {
  margin-top: 0;
  margin-bottom: 1.32em;
}

b, strong {
  @include font-face-bold;
}

i, em {

}

img {
  max-width: 100%;
  max-height: 100%;
}

.uppercase {
  text-transform: uppercase;
}

.nowrap {
  white-space: nowrap;
}

.mark {
  color: $primary;
}

.small {
  font-size: $font__size_small;
}

.text-white-shadow {
  color: $white!important;
  text-shadow: 0 4px 4px rgba(0,0,0,.25);
}

.mb {
  margin-bottom: $grid-gutter-width;
}

.block-info {
  margin: 20px 0;
  font-size: $font__size;
  line-height: (28/18);
  @include font-face-italic;
  color: $black;

  &__pic {
    flex-shrink: 0;
    margin-right: 27px;
    margin-bottom: 1rem;
  }

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }

  @include media-breakpoint-up(phx) {
    display: flex;
    align-items: flex-start;

    p {
      padding-top: 14px;
    }
  }

  @include media-breakpoint-up(tb) {
    font-size: 17px;

    &__pic {
      margin-bottom: 0;
    }
  }
}
