.map-section {
  position: relative;
  
  .container {
    position: relative;
  }
}

.map {
  height: 50vh;
  
  @include media-breakpoint-up(tb) {
    height: 550px;
  }
}

.map-panel {
  width: 100%;
  padding: 30px 0 25px;
  color: $black;
  z-index: 2;

  @include media-breakpoint-down(phx) {
    padding-bottom: 30px;
  }
  
  &-container {
    @extend .container;
    background: $white;
  }
  
  .h2 {
    margin-bottom: .43em;
  }
  
  a {
    text-decoration: underline;
    color: $black;
    
    &:hover {
      color: $primary;
    }
  }
  
  [href^='tel'] {
    text-decoration: none;
  }
  
  @include media-breakpoint-up(tbx) {
    position: absolute;
    top: 124px;
    left: 0;
    width: 420px;
    padding: 50px 40px 35px;
    background: $white;
    box-shadow: 0 5px 34px rgba(0,0,0,.35);
  
    &-container {
      background: none;
    }
  }
  
  @include media-breakpoint-up(lt) {
    left: $container__padding_lt;
  }

  .contacts-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
  }

  .contact {
    padding: 0 15px;
    margin: 0 0 15px;

    @include media-breakpoint-up(tbx) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    @include media-breakpoint-down(phx) {
      flex: 0 0 100%;
      max-width: 100%;
    }

    &:last-child {

      @include media-breakpoint-down(phx) {
        margin-bottom: 0;
      }
    }
  }
}
