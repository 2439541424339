.logo {
  img {
    height: 50px;
    width: auto;
  
    @include media-breakpoint-up(tb) {
      height: 80px;
    }
  }
}
