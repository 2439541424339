$primary:                 #1970C9;
$primary-light:           #378ADF;
$primary-lighter:         #549DE9;
$secondary:               #E7B075;
$secondary-light:         #FFD7AD;
$success:                 #5AB8B3;
$info:                    #6E6E6E;
$danger:                  red;
$light:                   #F5F5F5;

$white:                   #fff;
$black:                   #000;

$text__color:             #494949;
$text__color_light:       #999;
$text__color_grey:        #3F3F3F;
$title__color:            $black;

$border__color:           rgba(0,0,0,.15);
$border__radius:          5px;

$bg__block:               #F5F5F5;
$bg__block_dark:          #0D4F94;

$font__size_small:        14px;
$font__size:              15px;
$font__size_normal:       16px;
$font__size_large:        17px;
$font__size_h3:           24px;
$font__size_h4:           22px;
$font__size_h5:           20px;
$font__size_h6:           18px;
$font__line-height:       1.6;
$font__family:            'Roboto', sans-serif;

$container__width_max:    1170px;
$container__padding:      24px;
$container__padding_tb:   30px;
$container__padding_tbx:  60px;
$container__padding_lt:   80px;

$header__height:          110px;
$header__height-ph:       70px;

$field__height:           50px;


//----Bootstrap----

$theme-colors: () !default;
$theme-colors: map-merge(
    (
      "primary":    $primary,
      "secondary":  $secondary,
      "success":    $success,
      "info":       $info,
      "danger":     $danger,
      "light":      $light,
    ),
    $theme-colors
);

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
    (
      0: 0,
      1: ($spacer * 1),
      2: ($spacer * 2),
      3: ($spacer * 3),
      4: ($spacer * 4),
      5: ($spacer * 5),
      6: ($spacer * 6)
    ),
    $spacers
);

$grid-breakpoints: (
  ph:   0,        //phone
  phx:  576px,    //phone large
  tb:   768px,    //tablet
  tbx:  992px,    //tablet large
  lt:   1260px,   //laptop
  dt:   1400px,   //desktop
) !default;

//@include media-breakpoint-up(lt) { ... }
//@include media-breakpoint-down(lt) { ... }
//@include media-breakpoint-only(lt) { ... }

$container-max-widths: () !default;

$grid-gutter-width:   20px !default;
