.navigation {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  transform: translateX(100%);
  visibility: hidden;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
  background: #fff;
  transition: transform .5s 0s ease, visibility 0s .5s;
  z-index: 1006;
  
  @include media-breakpoint-up(phx) {
    width: 400px;
  }
}

.navigation_active {
  transform: translateX(0);
  visibility: visible;
  transition: transform .5s 0s ease, visibility 0s 0s;
  
  @include media-breakpoint-up(lt) {
    transform: translateX(100%);
    visibility: hidden;
  }
}

.navigation::-webkit-scrollbar {
  width: 5px;
}

.navigation::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.navigation__container {
  padding: 40px $container__padding 25px;
  
  @include media-breakpoint-up(phx) {
    padding: 60px 30px 30px;
  }
}

.navigation__footer {
  padding: 0 30px 30px;
}

.navigation-close {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 44px;
  height: 44px;
  cursor: pointer;
}

.navigation-close::before,
.navigation-close::after {
  content: '';
  position: absolute;
  top: 22px;
  left: 7px;
  display: block;
  width: 30px;
  height: 1px;
  background: #000;
  transform: rotate(45deg);
}

.navigation-close::after {
  transform: rotate(-45deg);
}

.navigation-blackout {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background: rgba(0,0,0,.7);
  z-index: 1005;
  
  @include media-breakpoint-up(lt) {
    display: none!important;
  }
}
