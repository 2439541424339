.bg {
  @extend .container;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 1;
  
  &-wrapper {
    position: absolute;
  }
  
  @include media-breakpoint-down(tb) {
    display: none;
  }
}
