@mixin font-face-regular() {
  font-weight: normal;
}

@mixin font-face-light() {
  font-weight: 300;
}

@mixin font-face-italic() {
  font-weight: normal;
  font-style: italic;
}

@mixin font-face-bold() {
  font-weight: bold;
}

@mixin font-face-medium() {
  font-weight: 500;
}

@mixin font-face-black() {
  font-weight: 900;
}

@mixin block-box-shadow() {
  box-shadow: 0 4px 15px rgba(0, 0, 0, .15);
}
