h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  color: $title__color;
  text-transform: none;
  @include font-face-bold;
  transition: color .25s;
}

h1 {
  margin-top: 0;
  margin-bottom: .75em;
  font-size: 50px;
  line-height: 1.24;

  @include media-breakpoint-down(phx) {
    font-size: 35px;
  }

  @include media-breakpoint-down(tb) {
    font-size: 48px;
  }
}

h2 {
  margin-bottom: .55em;
  margin-top: 0.9em;
  font-size: 46px;
  line-height: 1.17;

  @include media-breakpoint-down(phx) {
    font-size: 28px;
  }

  @include media-breakpoint-down(tb) {
    font-size: 35px;
  }
}

h3 {
  margin-bottom: 1em;
  margin-top: 1.2em;
  font-size: $font__size_h3;
  line-height: 1.25;

  @include media-breakpoint-down(phx) {
    font-size: 20px;
  }
}

h4 {
  margin-top: 2.5em;
  margin-bottom: .68em;
  font-size: $font__size_h4;
  line-height: 1.36;

  @include media-breakpoint-down(phx) {
    font-size: 18px;
  }
}

h5 {
  margin-top: 1em;
  margin-bottom: .41em;
  font-size: $font__size_h5;
  line-height: 1.4;
  @include font-face-regular;

  @include media-breakpoint-down(phx) {
    font-size: 18px;
  }
}

h6 {
  margin-top: 2.4em;
  margin-bottom: 1.4em;
  font-size: $font__size_h6;
  line-height: 1.4;
}

.h1 { @extend h1; }
.h2 { @extend h2; }
.h3 { @extend h3; }
.h4 { @extend h4; }
.h5 { @extend h5; }
.h6 { @extend h6; }

.h1, .h2, .h3, .h4, .h5, .h6 {
  &:first-child {
    margin-top: 0;
  }
}
