.form-light {
  .form-control {
    color: $white;
    border-color: $white;
  
    &::placeholder {
      color: $white;
    }
  }
  
  select {
    &.form-control {
      background-image: url("../images/arrow-select-white.svg");
      
      &:invalid {
        color: $white;
      }
    }
  }
  
  .custom-check {
    > span {
      color: $white;
    }
  }
}

.form-group {
  display: block;
  margin-bottom: 15px;
}

.form-control {
  width: 100%;
  height: $field__height;
  padding: 0 20px;
  font-size: $font__size_small;
  border: 1px solid $border__color;
  border-radius: 0;
  background: transparent;
  box-shadow: none;
  font-family: $font__family;
  @include font-face-regular;
  color: $text__color;
  transition: all .4s;

  &:hover,
  &:focus {
    border-color: $secondary;
  }

  &:focus {
    outline: none;
  }

  &.error {
    border-color: $danger;
  }
  
  &::placeholder {
    color: $text__color_light;
  }
}

textarea {
  display: block;

  &.form-control {
    padding-top: 12px;
    padding-bottom: 12px;
    height: auto;
  }
}

select {
  &.form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 40px;
    background-image: url("../images/arrow-select.svg");
    background-position: calc(100% - 20px) 50%;
    background-repeat: no-repeat;
    
    &:invalid {
      color: $text__color_light;
    }
  
    &::-ms-expand {
      display: none;
    }
  
    option {
      font-size: $font__size;
      color: $text__color;
      background-color: $white;
    }
  }
}

//----- custom checkbox/radio -----
input[type='checkbox'],
input[type='radio'] {
  position: absolute;
  top: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
}

.custom-check {
  position: relative;
  display: block;
  
  &:hover {
    input {
      &:not(:checked) {
        + span {
          &::before,
          &::after {
            color: $secondary;
          }
        }
      }
    }
  }
  
  + .custom-check {
    margin-top: 7px;
  }
  
  input {
    &:checked {
      + span {
        &::after {
          opacity: 1;
        }
      }
    }
  }
  
  input[type='radio'] {
    + span {
      &::before,
      &::after {
        border-radius: 50%;
      }
    }
  }
  
  > span {
    position: relative;
    display: inline-block;
    padding-left: 32px;
    color: $text__color;
    
    &::before,
    &::after {
      content: '';
      position: absolute;
      display: block;
      transition: .15s ease;
    }
    
    &::before {
      top: 0;
      left: 0;
      width: 22px;
      height: 22px;
      border: 1px solid currentColor;
    }
    
    &::after {
      top: 6px;
      left: 6px;
      width: 12px;
      height: 12px;
      background: currentColor;
      opacity: 0;
    }
  }
}

.custom-check-block {
  margin: 20px 0;
}

.custom-check-inline {
  display: flex;
  flex-wrap: wrap;
  margin: 20px -12px 10px;
  
  .custom-check {
    padding: 0 12px;
    margin-bottom: 10px;
    
    + .custom-check {
      margin-top: 0;
    }
  }
}
//----- END--custom checkbox/radio -----


