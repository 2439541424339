.profit {

  &__title {
    margin-bottom: .65em;
  }

  &__row {
    align-items: center;
  }

  &__col {

    &--left {
      flex: 0 0 50%;
      max-width: 50%;
      padding-right: 50px;
      margin-top: -6px;

      @include media-breakpoint-down(tb) {
        flex: 0 0 100%;
        max-width: 555px;
        padding-right: 10px;
        margin-top: 0;
        margin-bottom: 30px;
      }
    }

    &--right {
      flex: 0 0 50%;
      max-width: 50%;
      position: relative;

      @include media-breakpoint-down(tb) {
        flex: 0 0 100%;
        max-width: 595px;
      }
    }
  }

  &__pic {

    @include media-breakpoint-down(phx) {
      display: none;
    }
  }

  &__list {
    padding: 23px 23px 23px;
    position: absolute;
    left: 25px;
    right: 25px;
    bottom: 22px;
    border-radius: 5px;
    background: $success;

    @include media-breakpoint-down(phx) {
      position: relative;
      left: auto;
      right: auto;
      bottom: auto;
    }

    ol {
      margin-bottom: 0;
      font-size: 18px;

      @include media-breakpoint-down(ph) {
        font-size: 16px;
      }

      > li {
        margin-bottom: 0.34em;
        color: $white;

        &:last-child {
          margin-bottom: 0;
        }

        &:before {
          margin-right: 2px;
          color: $secondary-light;
        }
      }
    }
  }

  ul {
    margin-bottom: 2em;

    > li {
      margin-bottom: .58em;

      &:before {
        top: .5em
      }
    }
  }
}

.profit-item {
  padding: 15px 30px 8px;
  margin-bottom: 30px;
  background: $white;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);

  &__row {
    align-items: center;
  }

  &__col {

    &--pic {
      flex: 0 0 70px;
      max-width: 70px;
    }
  }

  &__text {
    margin-top: -8px;
    font-size: 18px;
    line-height: 1.5;

    @include media-breakpoint-down(ph) {
      font-size: 16px;
    }
  }
}