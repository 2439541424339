.pic-block {
  margin-top: 38px;
  margin-bottom: 33px;

  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.pic {
  margin-bottom: 30px;

  @include media-breakpoint-up(tbx) {
    margin-bottom: 0;

    img {
      width: 100%;
    }
  }

  img {
    border-radius: 5px;
  }
}
