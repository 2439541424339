.menu {
  display: none;
  flex-grow: 1;
  height: 100%;
  
  .navigation & {
    display: block;
  
    &__list {
      display: block;
      height: auto;
    }
    
    &__el {
      display: block;
      height: auto;
    }
    
    &__link {
      height: auto;
      padding: 15px 20px;
      font-size: $font__size_h4;
      font-weight: normal;
    }
  }
  
  &__list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    height: 100%;
  }
  
  &__el {
    display: inline-block;
    height: 100%;
    margin: 0;
    padding: 0;
    
    &::before {
      display: none;
    }
  }
  
  &__link {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 12px;
    font-size: $font__size_large;
    @include font-face-bold;
    color: $black;
    text-decoration: none;
  }
  
  a {
    &:hover {
      color: $primary;
    }
  }
  
  @include media-breakpoint-up(lt) {
    display: block;
  }
}
