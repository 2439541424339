.files {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -5px;
  padding: 25px 0 0;
}

.file {
  $self: &;
  margin: 0 0 10px;
  padding: 0 5px;
  max-width: 100%;
  width: 100%;
  
  &::before {
    display: none;
  }
  
  &.txt {
    #{$self}__link {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)' fill='%23fff'%3E%3Cpath d='M1.25 18.125V1.875c0-.345.28-.625.625-.625h9.375v2.5c0 .69.56 1.25 1.25 1.25H15v1.875h1.25v-2.5a.625.625 0 00-.181-.444l-3.75-3.75A.625.625 0 0011.875 0h-10C.839 0 0 .84 0 1.875v16.25C0 19.161.84 20 1.875 20H6.25v-1.25H1.875a.625.625 0 01-.625-.625z'/%3E%3Cpath d='M12.569 10.344l-.694 1.381-.694-1.381a.625.625 0 00-.556-.344h-1.25v1.25h.863l.937 1.875L10.237 15h-.862v1.25h1.25c.235 0 .45-.134.556-.344l.694-1.381.694 1.381c.106.21.32.343.556.344h1.25V15h-.863l-.937-1.875.938-1.875h.862V10h-1.25a.625.625 0 00-.556.344zM3.75 11.25h1.875v5h1.25v-5H8.75V10h-5v1.25zM15 10v1.25h1.875v5h1.25v-5H20V10h-5z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath fill='%23fff' d='M0 0h20v20H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
      }
  
      &:hover {
        &::before {
          
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)' fill='%23fff'%3E%3Cpath d='M1.25 18.125V1.875c0-.345.28-.625.625-.625h9.375v2.5c0 .69.56 1.25 1.25 1.25H15v1.875h1.25v-2.5a.625.625 0 00-.181-.444l-3.75-3.75A.625.625 0 0011.875 0h-10C.839 0 0 .84 0 1.875v16.25C0 19.161.84 20 1.875 20H6.25v-1.25H1.875a.625.625 0 01-.625-.625z'/%3E%3Cpath d='M12.569 10.344l-.694 1.381-.694-1.381a.625.625 0 00-.556-.344h-1.25v1.25h.863l.937 1.875L10.237 15h-.862v1.25h1.25c.235 0 .45-.134.556-.344l.694-1.381.694 1.381c.106.21.32.343.556.344h1.25V15h-.863l-.937-1.875.938-1.875h.862V10h-1.25a.625.625 0 00-.556.344zM3.75 11.25h1.875v5h1.25v-5H8.75V10h-5v1.25zM15 10v1.25h1.875v5h1.25v-5H20V10h-5z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath fill='%23fff' d='M0 0h20v20H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        }
      }
    }
  }
  
  &.pdf {
    #{$self}__link {
      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)' fill='%23fff'%3E%3Cpath d='M14.375 10h-2.5a.625.625 0 00-.625.625v5c0 .345.28.625.625.625h2.5c.69 0 1.25-.56 1.25-1.25v-3.75c0-.69-.56-1.25-1.25-1.25zm0 5H12.5v-3.75h1.875V15zM20 11.25V10h-3.125a.625.625 0 00-.625.625v5.625h1.25v-2.5H20V12.5h-2.5v-1.25H20z'/%3E%3Cpath d='M1.25 18.125V1.875c0-.345.28-.625.625-.625h9.375v2.5c0 .69.56 1.25 1.25 1.25H15v1.875h1.25v-2.5a.625.625 0 00-.181-.444l-3.75-3.75A.625.625 0 0011.875 0h-10C.839 0 0 .84 0 1.875v16.25C0 19.161.84 20 1.875 20H6.25v-1.25H1.875a.625.625 0 01-.625-.625z'/%3E%3Cpath d='M9.375 10h-2.5a.625.625 0 00-.625.625v5.625H7.5v-1.875h1.875c.69 0 1.25-.56 1.25-1.25V11.25c0-.69-.56-1.25-1.25-1.25zm0 3.125H7.5V11.25h1.875v1.875z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath fill='%23fff' d='M0 0h20v20H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
      }
    
      &:hover {
        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)' fill='%23fff'%3E%3Cpath d='M14.375 10h-2.5a.625.625 0 00-.625.625v5c0 .345.28.625.625.625h2.5c.69 0 1.25-.56 1.25-1.25v-3.75c0-.69-.56-1.25-1.25-1.25zm0 5H12.5v-3.75h1.875V15zM20 11.25V10h-3.125a.625.625 0 00-.625.625v5.625h1.25v-2.5H20V12.5h-2.5v-1.25H20z'/%3E%3Cpath d='M1.25 18.125V1.875c0-.345.28-.625.625-.625h9.375v2.5c0 .69.56 1.25 1.25 1.25H15v1.875h1.25v-2.5a.625.625 0 00-.181-.444l-3.75-3.75A.625.625 0 0011.875 0h-10C.839 0 0 .84 0 1.875v16.25C0 19.161.84 20 1.875 20H6.25v-1.25H1.875a.625.625 0 01-.625-.625z'/%3E%3Cpath d='M9.375 10h-2.5a.625.625 0 00-.625.625v5.625H7.5v-1.875h1.875c.69 0 1.25-.56 1.25-1.25V11.25c0-.69-.56-1.25-1.25-1.25zm0 3.125H7.5V11.25h1.875v1.875z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath fill='%23fff' d='M0 0h20v20H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
        }
      }
    }
  }
  
  &__link {
    display: flex;
    align-items: center;
    padding: 8px 30px 7px;
    min-height: 40px;
    font-size: 11px;
    letter-spacing: 0.15em;
    text-decoration: none;
    text-transform: uppercase;
    color: $white;
    background: $success;
    @include font-face-black;
    transition: .2s ease;
    
    &:hover {
      color: $white;
      background: $secondary;
    }
  
    &::before {
      content: '';
      flex-shrink: 0;
      display: block;
      margin-right: 10px;
      width: 20px;
      height: 20px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: contain;
      transition: background .2s;
    }
  }
  
  @include media-breakpoint-up(phx) {
    width: auto;
  }
}
