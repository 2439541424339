.banner {
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: $white;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  }
  
  &__inner {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 580px;
    max-height: calc(100vh - 70px);
  }
  
  .h1 {
    margin: 0 0 .68em;
  }
  
  &__text {
    margin-bottom: 2.7rem;
  }

  .btn {
    margin-right: 15px;
    margin-bottom: 10px;
  }
  
  @include media-breakpoint-up(tb) {
    font-size: $font__size_h6;
    line-height: 1.55;
    
    &__inner {
      max-height: calc(100vh - 110px);
      padding-right: 30%;
    }
  }
  
  @include media-breakpoint-up(lt) {
    &__inner {
      padding-top: 10px;
      padding-right: 45%;
    }
  }

  @include media-breakpoint-down(ph) {
    .h1 {
      font-size: 30px;
    }
  }
}
