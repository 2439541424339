.steps {
  max-width: 890px;
  margin: 0 auto;
}

.step {
  $self: &;
  position: relative;
  display: flex;
  align-items: flex-start;
  padding-bottom: 45px;
  
  &:nth-child(1) {
    #{$self}__num {
      background: $primary-light;
    }
  }
  
  &:nth-child(2) {
    #{$self}__num {
      background: $primary;
    }
  }
  
  &:nth-child(3) {
    #{$self}__num {
      background: #1F4F91;
    }
  }
  
  &:last-child {
    padding-bottom: 0;
    
    &::before,
    &::after {
      display: none;
    }
  }
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: (80px / 2 + 1px);
    display: block;
    width: 1px;
    border-left: 1px dashed $primary;
  }
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: (80px / 2 - 2px);
    display: block;
    width: 6px;
    height: 6px;
    border: 1px solid $primary;
    border-left: none;
    border-top: none;
    transform: rotate(45deg);
  }
  
  &__num {
    position: relative;
    flex-shrink: 0;
    width: 80px;
    margin-right: 20px;
    padding: 12px 0 13px;
    font-size: $font__size;
    line-height: 1.1;
    @include font-face-medium;
    text-align: center;
    color: $white;
  }
  
  @include media-breakpoint-up(tb) {
    &::before {
      left: (194px / 2 + 1px);
    }
  
    &::after {
      left: (194px / 2 - 2px);
    }
    
    &__num {
      width: 194px;
      margin-right: 30px;
      padding: 20px 0 22px;
      font-size: 35px;
    }
  }
  
  @include media-breakpoint-up(tbx) {
    &__num {
      font-size: 40px;
    }
  }

  @include media-breakpoint-down(phx) {
    display: block;
    padding-bottom: 10px;

    &:before,
    &:after {
      display: none;
    }

    &__num {
      margin-bottom: 15px;
    }
  }
}
