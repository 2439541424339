.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: $white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 20;
  
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $header__height-ph;
  }
  
  &__item {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    
    &:last-child {
      text-align: right;
      justify-content: flex-end;

      @include media-breakpoint-up(lt) {
        display: none;
      }
    }
  }
  
  @include media-breakpoint-up(tb) {
    &__inner {
      height: $header__height;
    }
    
    &__item {
      width: 18.7%;
    }
  }
}
