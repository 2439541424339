.hamburger {
  $self: &;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 44px;
  height: 44px;
  margin-left: 40px;
  cursor: pointer;

  @include media-breakpoint-up(lt) {
    display: none;
  }

  &:hover {
    #{$self}__icon {
      color: $primary;
    }
  }

  &__icon {
    position: relative;
    margin-top: -15px;
    color: $text__color;
    opacity: 1;
    transition: opacity .25s ease;

    &,
    &::before,
    &::after {
      display: block;
      width: 32px;
      height: 2px;
      background: currentColor;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      right: 0;
    }

    &::before {
      top: 8px;
      transition: width .25s ease-out;
    }

    &::after {
      top: 16px;
    }
  }
}
