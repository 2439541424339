.btn {
  position: relative;
  display: inline-block;
  flex-shrink: 0;
  height: 50px;
  padding: 0 30px;
  border: 1px solid transparent;
  font-size: 13px;
  font-family: $font__family;
  @include font-face-black;
  line-height: (50px - 2px);
  border-radius: 0;
  box-shadow: none;
  white-space: nowrap;
  -webkit-appearance:none;
  -moz-appearance:none;
  background-image: none;
  background: transparent;
  text-decoration: none;
  text-transform: uppercase;
  transition: all .3s;
  text-align: center;
  letter-spacing: .15em;
  vertical-align: top;
  outline: none;
  cursor: pointer;
  
  @include media-breakpoint-down(tb) {
    font-size: 12px;
  }
  
  &.w-100 {
    padding: 0;
  }
  
  &-small {
    height: 40px;
    line-height: (40px - 2px);
    padding: 0 25px;
    font-size: 11px;
  }

  &-primary {
    color: $white;
    background: $primary;
  
    &:hover,
    &:focus,
    &:active {
      color: $white;
      background: darken($primary, 10%);
    }
    &:focus {
      box-shadow: 0 0 0 2px rgba($primary,.5);
    }
  }
  
  &-secondary {
    color: $white;
    background: $secondary;
    
    &:hover,
    &:focus,
    &:active {
      color: $white;
      background: darken($secondary, 10%);
    }
    &:focus {
      box-shadow: 0 0 0 2px rgba($secondary,.5);
    }
  }
}

.btn-row {
  .btn {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}
