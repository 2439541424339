.advantages {
  font-size: 18px;
  line-height: 1.2;

  &:last-child {
    margin-bottom: 0;
  }

  > li {
    display: block;
    margin-bottom: 1.2em;

    &:last-child {
      margin-bottom: 0;
    }

    &::before {
      text-align: left;
      margin-bottom: 3px;
      color: $primary;
    }
  }

  &__title {
    margin-bottom: 5px;
    @include font-face-regular;
    color: $title__color;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .small {
    display: block;
    line-height: (22/14);
    margin-bottom: 0;
  }

  @include media-breakpoint-up(tb) {
    font-size: $font__size_h5;
    line-height: 1.3;

    > li {
      &::before {
        float: left;
        min-width: 32px;
        margin-right: 5px;
        margin-bottom: 0;
      }
    }
  }
}
