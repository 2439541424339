.form-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  text-align: center;
  
  @include media-breakpoint-down(phx) {
    .btn {
      width: 100%;
    }
  }

  .form {
    max-width: 920px;

    @include media-breakpoint-down(tb) {
      max-width: 720px;
    }
  }

  .form {
    width: 100%;
  }
}
