.card {
  display: flex;
  height: 100%;
  padding: 25px;
  @include block-box-shadow;
  border-radius: $border__radius;
  font-size: $font__size_small;
  line-height: (22/14);
  transform: translateY(0);
  transition: .25s ease-in-out;
  
  .svg {
    flex-shrink: 0;
  }
  
  ul {
    > li {
      margin-bottom: 1em;
    }
  }
  
  &__icon {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 40px;
    flex-shrink: 0;
    margin-right: $grid-gutter-width;
  }
  
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  @include media-breakpoint-up(tb) {
    &:hover {
      transform: translateY(-10px);
    }
  
    &__icon {
      width: 50px;
    }
  }
}
