.accordion {
  &__card {
    margin-bottom: 15px;
    
    &:last-child {
      margin-bottom: 0;
    }
  }
  
  &__head {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: auto;
    min-height: 40px;
    padding: 10px 20px;
    line-height: 1.3;
    letter-spacing: normal;
    text-transform: none;
    text-align: left;
    @include font-face-bold;
    color: $white;
    background: $primary-light;
    white-space: normal;
    
    &,
    &.btn {
      font-size: $font__size;
    }
    
    &::after {
      content: '';
      flex-shrink: 0;
      display: block;
      width: 10px;
      height: 10px;
      margin-left: 20px;
      border: 1px solid $white;
      border-top: none;
      border-left: none;
      transform: rotate(45deg);
    }
  }
  
  &__body {
    p, ul, li {
      &:last-child {
        margin-bottom: 0;
      }
    }

    p {
      margin-bottom: 1em;
    }
    
    &-inner {
      padding: 25px 20px 10px;
    }
  }
  
  @include media-breakpoint-up(tb) {
    &__card {
      margin-bottom: 30px;
    }
    
    &__head {
      min-height: 60px;
      padding: 15px 33px 15px 29px;
      
      &,
      &.btn {
        font-size: $font__size_h5;
      }
      
      &::after {
        width: 12px;
        height: 12px;
        margin-top: 4px;
      }
    }
    
    &__body {
      &-inner {
        padding: 25px 30px 0;
      }
    }
  }
  
  @include media-breakpoint-up(tbx) {
    &__head {
      &,
      &.btn {
        font-size: $font__size_h4;
      }
    }
  }
}
