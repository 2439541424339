.footer {
  padding: 21px 0 22px;
  background: $bg__block_dark;
  
  &,
  a {
    color: $white;
  }
  
  @include media-breakpoint-up(tb) {
    font-size: $font__size_normal;
  }
}
