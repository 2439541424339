ul,
ol {
  display: block;
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 1.1em;

  > li {
    position: relative;
    margin-top: 0;
    margin-bottom: 1.1em;
    text-align: left;
  }
}

p {
  + ul, ol {
    margin-top: 1.1em;
  }
}

ul {
  > li {
    padding-left: 20px;
    margin-bottom: .65em;

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      top: .65em;
      left: 0;
      font-size: 1em;
      background: $success;
    }
  }
}

ol {
  counter-reset: section;

  > li {
    display: flex;
    align-items: flex-start;

    &::before {
      content: counters(section, ".",  decimal-leading-zero) ". ";
      counter-increment: section;
      display: block;
      flex-shrink: 0;
      min-width: 30px;
      text-align: center;
      margin-right: 16px;
      @include font-face-bold;
      color: $success;
    }
  }
}

.unstyled {
  > li {
    padding-left: 0;
    
    &::before {
      display: none;
    }
  }
}
