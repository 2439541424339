.container,
.bg,
.map-panel-container {
	width: 100%;
	padding-right: 10px;
	padding-left: 10px;
	margin-right: auto;
	margin-left: auto;
}

.container-fluid {
	width: 100%;
	padding-right: 10px;
	padding-left: 10px;
	margin-right: auto;
	margin-left: auto;
}

.row {
	display: flex;
	flex-wrap: wrap;
	margin-right: -10px;
	margin-left: -10px;
}

.no-gutters {
	margin-right: 0;
	margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
	padding-right: 0;
	padding-left: 0;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-phx-1,
.col-phx-2,
.col-phx-3,
.col-phx-4,
.col-phx-5,
.col-phx-6,
.col-phx-7,
.col-phx-8,
.col-phx-9,
.col-phx-10,
.col-phx-11,
.col-phx-12,
.col-phx,
.col-phx-auto,
.col-tb-1,
.col-tb-2,
.col-tb-3,
.col-tb-4,
.col-tb-5,
.col-tb-6,
.col-tb-7,
.col-tb-8,
.col-tb-9,
.col-tb-10,
.col-tb-11,
.col-tb-12,
.col-tb,
.col-tb-auto,
.col-tbx-1,
.col-tbx-2,
.col-tbx-3,
.col-tbx-4,
.col-tbx-5,
.col-tbx-6,
.col-tbx-7,
.col-tbx-8,
.col-tbx-9,
.col-tbx-10,
.col-tbx-11,
.col-tbx-12,
.col-tbx,
.col-tbx-auto,
.col-lt-1,
.col-lt-2,
.col-lt-3,
.col-lt-4,
.col-lt-5,
.col-lt-6,
.col-lt-7,
.col-lt-8,
.col-lt-9,
.col-lt-10,
.col-lt-11,
.col-lt-12,
.col-lt,
.col-lt-auto,
.col-dt-1,
.col-dt-2,
.col-dt-3,
.col-dt-4,
.col-dt-5,
.col-dt-6,
.col-dt-7,
.col-dt-8,
.col-dt-9,
.col-dt-10,
.col-dt-11,
.col-dt-12,
.col-dt,
.col-dt-auto {
	position: relative;
	width: 100%;
	padding-right: 10px;
	padding-left: 10px;
}

.col {
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
}

.row-cols-1 > * {
	flex: 0 0 100%;
	max-width: 100%;
}

.row-cols-2 > * {
	flex: 0 0 50%;
	max-width: 50%;
}

.row-cols-3 > * {
	flex: 0 0 33.33333%;
	max-width: 33.33333%;
}

.row-cols-4 > * {
	flex: 0 0 25%;
	max-width: 25%;
}

.row-cols-5 > * {
	flex: 0 0 20%;
	max-width: 20%;
}

.row-cols-6 > * {
	flex: 0 0 16.66667%;
	max-width: 16.66667%;
}

.col-auto {
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
}

.col-1 {
	flex: 0 0 8.33333%;
	max-width: 8.33333%;
}

.col-2 {
	flex: 0 0 16.66667%;
	max-width: 16.66667%;
}

.col-3 {
	flex: 0 0 25%;
	max-width: 25%;
}

.col-4 {
	flex: 0 0 33.33333%;
	max-width: 33.33333%;
}

.col-5 {
	flex: 0 0 41.66667%;
	max-width: 41.66667%;
}

.col-6 {
	flex: 0 0 50%;
	max-width: 50%;
}

.col-7 {
	flex: 0 0 58.33333%;
	max-width: 58.33333%;
}

.col-8 {
	flex: 0 0 66.66667%;
	max-width: 66.66667%;
}

.col-9 {
	flex: 0 0 75%;
	max-width: 75%;
}

.col-10 {
	flex: 0 0 83.33333%;
	max-width: 83.33333%;
}

.col-11 {
	flex: 0 0 91.66667%;
	max-width: 91.66667%;
}

.col-12 {
	flex: 0 0 100%;
	max-width: 100%;
}

.order-first {
	order: -1;
}

.order-last {
	order: 13;
}

.order-0 {
	order: 0;
}

.order-1 {
	order: 1;
}

.order-2 {
	order: 2;
}

.order-3 {
	order: 3;
}

.order-4 {
	order: 4;
}

.order-5 {
	order: 5;
}

.order-6 {
	order: 6;
}

.order-7 {
	order: 7;
}

.order-8 {
	order: 8;
}

.order-9 {
	order: 9;
}

.order-10 {
	order: 10;
}

.order-11 {
	order: 11;
}

.order-12 {
	order: 12;
}

.offset-1 {
	margin-left: 8.33333%;
}

.offset-2 {
	margin-left: 16.66667%;
}

.offset-3 {
	margin-left: 25%;
}

.offset-4 {
	margin-left: 33.33333%;
}

.offset-5 {
	margin-left: 41.66667%;
}

.offset-6 {
	margin-left: 50%;
}

.offset-7 {
	margin-left: 58.33333%;
}

.offset-8 {
	margin-left: 66.66667%;
}

.offset-9 {
	margin-left: 75%;
}

.offset-10 {
	margin-left: 83.33333%;
}

.offset-11 {
	margin-left: 91.66667%;
}

.fade {
	transition: opacity 0.15s linear;
}

.fade:not(.show) {
	opacity: 0;
}

.collapse:not(.show) {
	display: none;
}

.collapsing {
	position: relative;
	height: 0;
	overflow: hidden;
	transition: height 0.35s ease;
}

.align-baseline {
	vertical-align: baseline !important;
}

.align-top {
	vertical-align: top !important;
}

.align-middle {
	vertical-align: middle !important;
}

.align-bottom {
	vertical-align: bottom !important;
}

.align-text-bottom {
	vertical-align: text-bottom !important;
}

.align-text-top {
	vertical-align: text-top !important;
}

.bg-primary {
	background-color: #1970C9 !important;
}

a.bg-primary:hover,
a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
	background-color: #13579c !important;
}

.bg-secondary {
	background-color: #E7B075 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
	background-color: #df974a !important;
}

.bg-success {
	background-color: #5AB8B3 !important;
}

a.bg-success:hover,
a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
	background-color: #439c97 !important;
}

.bg-info {
	background-color: #6E6E6E !important;
}

a.bg-info:hover,
a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
	background-color: #555555 !important;
}

.bg-warning {
	background-color: #ffc107 !important;
}

a.bg-warning:hover,
a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
	background-color: #d39e00 !important;
}

.bg-danger {
	background-color: red !important;
}

a.bg-danger:hover,
a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
	background-color: #cc0000 !important;
}

.bg-light {
	background-color: #F5F5F5 !important;
}

a.bg-light:hover,
a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
	background-color: gainsboro !important;
}

.bg-dark {
	background-color: #343a40 !important;
}

a.bg-dark:hover,
a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
	background-color: #1d2124 !important;
}

.bg-white {
	background-color: #fff !important;
}

.bg-transparent {
	background-color: transparent !important;
}

.border {
	border: 1px solid #dee2e6 !important;
}

.border-top {
	border-top: 1px solid #dee2e6 !important;
}

.border-right {
	border-right: 1px solid #dee2e6 !important;
}

.border-bottom {
	border-bottom: 1px solid #dee2e6 !important;
}

.border-left {
	border-left: 1px solid #dee2e6 !important;
}

.border-0 {
	border: 0 !important;
}

.border-top-0 {
	border-top: 0 !important;
}

.border-right-0 {
	border-right: 0 !important;
}

.border-bottom-0 {
	border-bottom: 0 !important;
}

.border-left-0 {
	border-left: 0 !important;
}

.border-primary {
	border-color: #1970C9 !important;
}

.border-secondary {
	border-color: #E7B075 !important;
}

.border-success {
	border-color: #5AB8B3 !important;
}

.border-info {
	border-color: #6E6E6E !important;
}

.border-warning {
	border-color: #ffc107 !important;
}

.border-danger {
	border-color: red !important;
}

.border-light {
	border-color: #F5F5F5 !important;
}

.border-dark {
	border-color: #343a40 !important;
}

.border-white {
	border-color: #fff !important;
}

.rounded-sm {
	border-radius: 0.2rem !important;
}

.rounded {
	border-radius: 0.25rem !important;
}

.rounded-top {
	border-top-left-radius: 0.25rem !important;
	border-top-right-radius: 0.25rem !important;
}

.rounded-right {
	border-top-right-radius: 0.25rem !important;
	border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
	border-bottom-right-radius: 0.25rem !important;
	border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
	border-top-left-radius: 0.25rem !important;
	border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
	border-radius: 0.3rem !important;
}

.rounded-circle {
	border-radius: 50% !important;
}

.rounded-pill {
	border-radius: 50rem !important;
}

.rounded-0 {
	border-radius: 0 !important;
}

.clearfix::after {
	display: block;
	clear: both;
	content: "";
}

.d-none {
	display: none !important;
}

.d-inline {
	display: inline !important;
}

.d-inline-block {
	display: inline-block !important;
}

.d-block {
	display: block !important;
}

.d-table {
	display: table !important;
}

.d-table-row {
	display: table-row !important;
}

.d-table-cell {
	display: table-cell !important;
}

.d-flex {
	display: flex !important;
}

.d-inline-flex {
	display: inline-flex !important;
}

.embed-responsive {
	position: relative;
	display: block;
	width: 100%;
	padding: 0;
	overflow: hidden;
}

.embed-responsive::before {
	display: block;
	content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border: 0;
}

.embed-responsive-21by9::before {
	padding-top: 42.85714%;
}

.embed-responsive-16by9::before {
	padding-top: 56.25%;
}

.embed-responsive-4by3::before {
	padding-top: 75%;
}

.embed-responsive-1by1::before {
	padding-top: 100%;
}

.flex-row {
	flex-direction: row !important;
}

.flex-column {
	flex-direction: column !important;
}

.flex-row-reverse {
	flex-direction: row-reverse !important;
}

.flex-column-reverse {
	flex-direction: column-reverse !important;
}

.flex-wrap {
	flex-wrap: wrap !important;
}

.flex-nowrap {
	flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
	flex-wrap: wrap-reverse !important;
}

.flex-fill {
	flex: 1 1 auto !important;
}

.flex-grow-0 {
	flex-grow: 0 !important;
}

.flex-grow-1 {
	flex-grow: 1 !important;
}

.flex-shrink-0 {
	flex-shrink: 0 !important;
}

.flex-shrink-1 {
	flex-shrink: 1 !important;
}

.justify-content-start {
	justify-content: flex-start !important;
}

.justify-content-end {
	justify-content: flex-end !important;
}

.justify-content-center {
	justify-content: center !important;
}

.justify-content-between {
	justify-content: space-between !important;
}

.justify-content-around {
	justify-content: space-around !important;
}

.align-items-start {
	align-items: flex-start !important;
}

.align-items-end {
	align-items: flex-end !important;
}

.align-items-center {
	align-items: center !important;
}

.align-items-baseline {
	align-items: baseline !important;
}

.align-items-stretch {
	align-items: stretch !important;
}

.align-content-start {
	align-content: flex-start !important;
}

.align-content-end {
	align-content: flex-end !important;
}

.align-content-center {
	align-content: center !important;
}

.align-content-between {
	align-content: space-between !important;
}

.align-content-around {
	align-content: space-around !important;
}

.align-content-stretch {
	align-content: stretch !important;
}

.align-self-auto {
	align-self: auto !important;
}

.align-self-start {
	align-self: flex-start !important;
}

.align-self-end {
	align-self: flex-end !important;
}

.align-self-center {
	align-self: center !important;
}

.align-self-baseline {
	align-self: baseline !important;
}

.align-self-stretch {
	align-self: stretch !important;
}

.float-left {
	float: left !important;
}

.float-right {
	float: right !important;
}

.float-none {
	float: none !important;
}

.overflow-auto {
	overflow: auto !important;
}

.overflow-hidden {
	overflow: hidden !important;
}

.position-static {
	position: static !important;
}

.position-relative {
	position: relative !important;
}

.position-absolute {
	position: absolute !important;
}

.position-fixed {
	position: fixed !important;
}

.position-sticky {
	position: sticky !important;
}

.fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}

.fixed-bottom {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1030;
}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	padding: 0;
	margin: -1px;
	overflow: hidden;
	clip: rect(0, 0, 0, 0);
	white-space: nowrap;
	border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
	position: static;
	width: auto;
	height: auto;
	overflow: visible;
	clip: auto;
	white-space: normal;
}

.shadow-sm {
	box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
	box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
	box-shadow: none !important;
}

.w-25 {
	width: 25% !important;
}

.w-50 {
	width: 50% !important;
}

.w-75 {
	width: 75% !important;
}

.w-100 {
	width: 100% !important;
}

.w-auto {
	width: auto !important;
}

.h-25 {
	height: 25% !important;
}

.h-50 {
	height: 50% !important;
}

.h-75 {
	height: 75% !important;
}

.h-100 {
	height: 100% !important;
}

.h-auto {
	height: auto !important;
}

.mw-100 {
	max-width: 100% !important;
}

.mh-100 {
	max-height: 100% !important;
}

.min-vw-100 {
	min-width: 100vw !important;
}

.min-vh-100 {
	min-height: 100vh !important;
}

.vw-100 {
	width: 100vw !important;
}

.vh-100 {
	height: 100vh !important;
}

.stretched-link::after {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	pointer-events: auto;
	content: "";
	background-color: rgba(0, 0, 0, 0);
}

.m-0 {
	margin: 0 !important;
}

.mt-0,
.my-0 {
	margin-top: 0 !important;
}

.mr-0,
.mx-0 {
	margin-right: 0 !important;
}

.mb-0,
.my-0 {
	margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
	margin-left: 0 !important;
}

.m-1 {
	margin: 1rem !important;
}

.mt-1,
.my-1 {
	margin-top: 1rem !important;
}

.mr-1,
.mx-1 {
	margin-right: 1rem !important;
}

.mb-1,
.my-1 {
	margin-bottom: 1rem !important;
}

.ml-1,
.mx-1 {
	margin-left: 1rem !important;
}

.m-2 {
	margin: 2rem !important;
}

.mt-2,
.my-2 {
	margin-top: 2rem !important;
}

.mr-2,
.mx-2 {
	margin-right: 2rem !important;
}

.mb-2,
.my-2 {
	margin-bottom: 2rem !important;
}

.ml-2,
.mx-2 {
	margin-left: 2rem !important;
}

.m-3 {
	margin: 3rem !important;
}

.mt-3,
.my-3 {
	margin-top: 3rem !important;
}

.mr-3,
.mx-3 {
	margin-right: 3rem !important;
}

.mb-3,
.my-3 {
	margin-bottom: 3rem !important;
}

.ml-3,
.mx-3 {
	margin-left: 3rem !important;
}

.m-4 {
	margin: 4rem !important;
}

.mt-4,
.my-4 {
	margin-top: 4rem !important;
}

.mr-4,
.mx-4 {
	margin-right: 4rem !important;
}

.mb-4,
.my-4 {
	margin-bottom: 4rem !important;
}

.ml-4,
.mx-4 {
	margin-left: 4rem !important;
}

.m-5 {
	margin: 5rem !important;
}

.mt-5,
.my-5 {
	margin-top: 5rem !important;
}

.mr-5,
.mx-5 {
	margin-right: 5rem !important;
}

.mb-5,
.my-5 {
	margin-bottom: 5rem !important;
}

.ml-5,
.mx-5 {
	margin-left: 5rem !important;
}

.m-6 {
	margin: 6rem !important;
}

.mt-6,
.my-6 {
	margin-top: 6rem !important;
}

.mr-6,
.mx-6 {
	margin-right: 6rem !important;
}

.mb-6,
.my-6 {
	margin-bottom: 6rem !important;
}

.ml-6,
.mx-6 {
	margin-left: 6rem !important;
}

.p-0 {
	padding: 0 !important;
}

.pt-0,
.py-0 {
	padding-top: 0 !important;
}

.pr-0,
.px-0 {
	padding-right: 0 !important;
}

.pb-0,
.py-0 {
	padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
	padding-left: 0 !important;
}

.p-1 {
	padding: 1rem !important;
}

.pt-1,
.py-1 {
	padding-top: 1rem !important;
}

.pr-1,
.px-1 {
	padding-right: 1rem !important;
}

.pb-1,
.py-1 {
	padding-bottom: 1rem !important;
}

.pl-1,
.px-1 {
	padding-left: 1rem !important;
}

.p-2 {
	padding: 2rem !important;
}

.pt-2,
.py-2 {
	padding-top: 2rem !important;
}

.pr-2,
.px-2 {
	padding-right: 2rem !important;
}

.pb-2,
.py-2 {
	padding-bottom: 2rem !important;
}

.pl-2,
.px-2 {
	padding-left: 2rem !important;
}

.p-3 {
	padding: 3rem !important;
}

.pt-3,
.py-3 {
	padding-top: 3rem !important;
}

.pr-3,
.px-3 {
	padding-right: 3rem !important;
}

.pb-3,
.py-3 {
	padding-bottom: 3rem !important;
}

.pl-3,
.px-3 {
	padding-left: 3rem !important;
}

.p-4 {
	padding: 4rem !important;
}

.pt-4,
.py-4 {
	padding-top: 4rem !important;
}

.pr-4,
.px-4 {
	padding-right: 4rem !important;
}

.pb-4,
.py-4 {
	padding-bottom: 4rem !important;
}

.pl-4,
.px-4 {
	padding-left: 4rem !important;
}

.p-5 {
	padding: 5rem !important;
}

.pt-5,
.py-5 {
	padding-top: 5rem !important;
}

.pr-5,
.px-5 {
	padding-right: 5rem !important;
}

.pb-5,
.py-5 {
	padding-bottom: 5rem !important;
}

.pl-5,
.px-5 {
	padding-left: 5rem !important;
}

.p-6 {
	padding: 6rem !important;
}

.pt-6,
.py-6 {
	padding-top: 6rem !important;
}

.pr-6,
.px-6 {
	padding-right: 6rem !important;
}

.pb-6,
.py-6 {
	padding-bottom: 6rem !important;
}

.pl-6,
.px-6 {
	padding-left: 6rem !important;
}

.m-n1 {
	margin: -1rem !important;
}

.mt-n1,
.my-n1 {
	margin-top: -1rem !important;
}

.mr-n1,
.mx-n1 {
	margin-right: -1rem !important;
}

.mb-n1,
.my-n1 {
	margin-bottom: -1rem !important;
}

.ml-n1,
.mx-n1 {
	margin-left: -1rem !important;
}

.m-n2 {
	margin: -2rem !important;
}

.mt-n2,
.my-n2 {
	margin-top: -2rem !important;
}

.mr-n2,
.mx-n2 {
	margin-right: -2rem !important;
}

.mb-n2,
.my-n2 {
	margin-bottom: -2rem !important;
}

.ml-n2,
.mx-n2 {
	margin-left: -2rem !important;
}

.m-n3 {
	margin: -3rem !important;
}

.mt-n3,
.my-n3 {
	margin-top: -3rem !important;
}

.mr-n3,
.mx-n3 {
	margin-right: -3rem !important;
}

.mb-n3,
.my-n3 {
	margin-bottom: -3rem !important;
}

.ml-n3,
.mx-n3 {
	margin-left: -3rem !important;
}

.m-n4 {
	margin: -4rem !important;
}

.mt-n4,
.my-n4 {
	margin-top: -4rem !important;
}

.mr-n4,
.mx-n4 {
	margin-right: -4rem !important;
}

.mb-n4,
.my-n4 {
	margin-bottom: -4rem !important;
}

.ml-n4,
.mx-n4 {
	margin-left: -4rem !important;
}

.m-n5 {
	margin: -5rem !important;
}

.mt-n5,
.my-n5 {
	margin-top: -5rem !important;
}

.mr-n5,
.mx-n5 {
	margin-right: -5rem !important;
}

.mb-n5,
.my-n5 {
	margin-bottom: -5rem !important;
}

.ml-n5,
.mx-n5 {
	margin-left: -5rem !important;
}

.m-n6 {
	margin: -6rem !important;
}

.mt-n6,
.my-n6 {
	margin-top: -6rem !important;
}

.mr-n6,
.mx-n6 {
	margin-right: -6rem !important;
}

.mb-n6,
.my-n6 {
	margin-bottom: -6rem !important;
}

.ml-n6,
.mx-n6 {
	margin-left: -6rem !important;
}

.m-auto {
	margin: auto !important;
}

.mt-auto,
.my-auto {
	margin-top: auto !important;
}

.mr-auto,
.mx-auto {
	margin-right: auto !important;
}

.mb-auto,
.my-auto {
	margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
	margin-left: auto !important;
}

.text-monospace {
	font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
	text-align: justify !important;
}

.text-wrap {
	white-space: normal !important;
}

.text-nowrap {
	white-space: nowrap !important;
}

.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.text-left {
	text-align: left !important;
}

.text-right {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

.text-lowercase {
	text-transform: lowercase !important;
}

.text-uppercase {
	text-transform: uppercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.font-weight-light {
	font-weight: 300 !important;
}

.font-weight-lighter {
	font-weight: lighter !important;
}

.font-weight-normal {
	font-weight: 400 !important;
}

.font-weight-bold {
	font-weight: 700 !important;
}

.font-weight-bolder {
	font-weight: bolder !important;
}

.font-italic {
	font-style: italic !important;
}

.text-white {
	color: #fff !important;
}

.text-primary {
	color: #1970C9 !important;
}

a.text-primary:hover,
a.text-primary:focus {
	color: #114a85 !important;
}

.text-secondary {
	color: #E7B075 !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
	color: #dc8b34 !important;
}

.text-success {
	color: #5AB8B3 !important;
}

a.text-success:hover,
a.text-success:focus {
	color: #3b8a86 !important;
}

.text-info {
	color: #6E6E6E !important;
}

a.text-info:hover,
a.text-info:focus {
	color: #484848 !important;
}

.text-warning {
	color: #ffc107 !important;
}

a.text-warning:hover,
a.text-warning:focus {
	color: #ba8b00 !important;
}

.text-danger {
	color: red !important;
}

a.text-danger:hover,
a.text-danger:focus {
	color: #b30000 !important;
}

.text-light {
	color: #F5F5F5 !important;
}

a.text-light:hover,
a.text-light:focus {
	color: #cfcfcf !important;
}

.text-dark {
	color: #343a40 !important;
}

a.text-dark:hover,
a.text-dark:focus {
	color: #121416 !important;
}

.text-body {
	color: #212529 !important;
}

.text-muted {
	color: #6c757d !important;
}

.text-black-50 {
	color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
	color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}

.text-decoration-none {
	text-decoration: none !important;
}

.text-break {
	word-break: break-word !important;
	overflow-wrap: break-word !important;
}

.text-reset {
	color: inherit !important;
}

.visible {
	visibility: visible !important;
}

.invisible {
	visibility: hidden !important;
}

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html,
body {
	height: 100%;
	font-size: 15px;
	line-height: 1.6;
	font-family: "Roboto", sans-serif;
	font-weight: normal;
	color: #494949;
}

html {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
	min-width: 300px;
	overflow-x: auto;
	background-color: #fff;
}

.page {
	display: flex;
	flex-direction: column;
	width: 100%;
	min-height: 100%;
	overflow: hidden;
	background-color: #fff;
	padding-top: 70px;
}

.main {
	display: block;
	flex-grow: 1;
	flex-basis: 0;
}

.container,
.bg,
.map-panel-container {
	width: 100%;
	max-width: 1330px;
	padding: 0 24px;
	margin: 0 auto;
}

.section {
	position: relative;
	padding: 60px 0;
}

.section .container,
.section .bg,
.section .map-panel-container {
	position: relative;
	z-index: 2;
}

.section.bg-success {
	color: #fff;
}

.section_bg-image {
	position: relative;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	color: #fff;
}

.section_bg-image::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	background: rgba(0, 0, 0, 0.6);
}

.section_bg-image > .container,
.section_bg-image > .bg,
.section_bg-image > .map-panel-container {
	position: relative;
}

.section_bg-half {
	background: #F5F5F5;
}

.section--about-descr .h2 {
	margin-bottom: .32em;
}

.section--about-descr .btn-wrap {
	margin-top: 25px;
}

.section--about-descr .btn-wrap .btn {
	margin-right: 11px;
	margin-bottom: 10px;
}

.section--profit {
	padding-bottom: 30px;
}

.section--tokens {
	padding-top: 80px;
	padding-bottom: 20px;
}

.section--tokens .col-pic {
	flex: 0 0 39.2%;
	max-width: 39.2%;
	padding-right: 36px;
}

.section--tokens .col-text {
	flex: 0 0 60.8%;
	max-width: 60.8%;
}

.section--how-to-buy {
	padding-top: 20px;
	padding-bottom: 50px;
}

.section--faq {
	padding-top: 50px;
}

.section--btn {
	padding-top: 35px;
	padding-bottom: 25px;
}

.section--btn .container,
.section--btn .bg,
.section--btn .map-panel-container {
	text-align: center;
}

.section--btn .btn {
	margin-right: 15px;
	margin-bottom: 10px;
}

.section-bg-left {
	position: relative;
	margin-bottom: 30px;
}

.section-bg-left * {
	position: relative;
	z-index: 2;
}

.section-head {
	margin-bottom: 1.5rem;
}

.section-head .h2 {
	margin: 0;
}

.section-head .link {
	display: inline-block;
	margin-top: 10px;
}

a {
	color: #1970C9;
	transition: color .2s;
}

a:hover {
	text-decoration: none;
}

a:focus {
	color: #13579c;
	outline: none;
}

a[href^='tel:'] {
	white-space: nowrap;
}

p {
	margin-top: 0;
	margin-bottom: 1.32em;
}

b,
strong {
	font-weight: bold;
}

img {
	max-width: 100%;
	max-height: 100%;
}

.uppercase {
	text-transform: uppercase;
}

.nowrap {
	white-space: nowrap;
}

.mark {
	color: #1970C9;
}

.small {
	font-size: 14px;
}

.text-white-shadow {
	color: #fff !important;
	text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.mb {
	margin-bottom: 20px;
}

.block-info {
	margin: 20px 0;
	font-size: 15px;
	line-height: 1.55556;
	font-weight: normal;
	font-style: italic;
	color: #000;
}

.block-info__pic {
	flex-shrink: 0;
	margin-right: 27px;
	margin-bottom: 1rem;
}

.block-info p:last-child {
	margin-bottom: 0;
}

h1,
.h1,
.h1,
h2,
.h2,
.h2,
h3,
.h3,
.h3,
h4,
.h4,
.h4,
h5,
.h5,
.h5,
h6,
.h6,
.h6 {
	color: #000;
	text-transform: none;
	font-weight: bold;
	transition: color .25s;
}

h1,
.h1 {
	margin-top: 0;
	margin-bottom: .75em;
	font-size: 50px;
	line-height: 1.24;
}

h2,
.h2 {
	margin-bottom: .55em;
	margin-top: 0.9em;
	font-size: 46px;
	line-height: 1.17;
}

h3,
.h3 {
	margin-bottom: 1em;
	margin-top: 1.2em;
	font-size: 24px;
	line-height: 1.25;
}

h4,
.h4 {
	margin-top: 2.5em;
	margin-bottom: .68em;
	font-size: 22px;
	line-height: 1.36;
}

h5,
.h5 {
	margin-top: 1em;
	margin-bottom: .41em;
	font-size: 20px;
	line-height: 1.4;
	font-weight: normal;
}

h6,
.h6 {
	margin-top: 2.4em;
	margin-bottom: 1.4em;
	font-size: 18px;
	line-height: 1.4;
}

.h1:first-child,
.h2:first-child,
.h3:first-child,
.h4:first-child,
.h5:first-child,
.h6:first-child {
	margin-top: 0;
}

ul,
ol {
	display: block;
	list-style: none;
	padding: 0;
	margin-top: 0;
	margin-bottom: 1.1em;
}

ul > li,
ol > li {
	position: relative;
	margin-top: 0;
	margin-bottom: 1.1em;
	text-align: left;
}

p + ul,
p ol {
	margin-top: 1.1em;
}

ul > li {
	padding-left: 20px;
	margin-bottom: .65em;
}

ul > li::before {
	content: '';
	display: block;
	position: absolute;
	width: 7px;
	height: 7px;
	border-radius: 50%;
	top: .65em;
	left: 0;
	font-size: 1em;
	background: #5AB8B3;
}

ol {
	counter-reset: section;
}

ol > li {
	display: flex;
	align-items: flex-start;
}

ol > li::before {
	content: counters(section, ".", decimal-leading-zero) ". ";
	counter-increment: section;
	display: block;
	flex-shrink: 0;
	min-width: 30px;
	text-align: center;
	margin-right: 16px;
	font-weight: bold;
	color: #5AB8B3;
}

.unstyled > li {
	padding-left: 0;
}

.unstyled > li::before {
	display: none;
}

.btn {
	position: relative;
	display: inline-block;
	flex-shrink: 0;
	height: 50px;
	padding: 0 30px;
	border: 1px solid transparent;
	font-size: 13px;
	font-family: "Roboto", sans-serif;
	font-weight: 900;
	line-height: 48px;
	border-radius: 0;
	box-shadow: none;
	white-space: nowrap;
	-webkit-appearance: none;
	-moz-appearance: none;
	background-image: none;
	background: transparent;
	text-decoration: none;
	text-transform: uppercase;
	transition: all .3s;
	text-align: center;
	letter-spacing: .15em;
	vertical-align: top;
	outline: none;
	cursor: pointer;
}

.btn.w-100 {
	padding: 0;
}

.btn-small {
	height: 40px;
	line-height: 38px;
	padding: 0 25px;
	font-size: 11px;
}

.btn-primary {
	color: #fff;
	background: #1970C9;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
	color: #fff;
	background: #13579c;
}

.btn-primary:focus {
	box-shadow: 0 0 0 2px rgba(25, 112, 201, 0.5);
}

.btn-secondary {
	color: #fff;
	background: #E7B075;
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:active {
	color: #fff;
	background: #df974a;
}

.btn-secondary:focus {
	box-shadow: 0 0 0 2px rgba(231, 176, 117, 0.5);
}

.btn-row .btn {
	margin-right: 10px;
	margin-bottom: 10px;
}

.form-light .form-control {
	color: #fff;
	border-color: #fff;
}

.form-light .form-control::placeholder {
	color: #fff;
}

.form-light select.form-control {
	background-image: url("../images/arrow-select-white.svg");
}

.form-light select.form-control:invalid {
	color: #fff;
}

.form-light .custom-check > span {
	color: #fff;
}

.form-group {
	display: block;
	margin-bottom: 15px;
}

.form-control {
	width: 100%;
	height: 50px;
	padding: 0 20px;
	font-size: 14px;
	border: 1px solid rgba(0, 0, 0, 0.15);
	border-radius: 0;
	background: transparent;
	box-shadow: none;
	font-family: "Roboto", sans-serif;
	font-weight: normal;
	color: #494949;
	transition: all .4s;
}

.form-control:hover,
.form-control:focus {
	border-color: #E7B075;
}

.form-control:focus {
	outline: none;
}

.form-control.error {
	border-color: red;
}

.form-control::placeholder {
	color: #999;
}

textarea {
	display: block;
}

textarea.form-control {
	padding-top: 12px;
	padding-bottom: 12px;
	height: auto;
}

select.form-control {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	padding-right: 40px;
	background-image: url("../images/arrow-select.svg");
	background-position: calc(100% - 20px) 50%;
	background-repeat: no-repeat;
}

select.form-control:invalid {
	color: #999;
}

select.form-control::-ms-expand {
	display: none;
}

select.form-control option {
	font-size: 15px;
	color: #494949;
	background-color: #fff;
}

input[type='checkbox'],
input[type='radio'] {
	position: absolute;
	top: 0;
	width: 1px;
	height: 1px;
	opacity: 0;
}

.custom-check {
	position: relative;
	display: block;
}

.custom-check:hover input:not(:checked) + span::before,
.custom-check:hover input:not(:checked) + span::after {
	color: #E7B075;
}

.custom-check + .custom-check {
	margin-top: 7px;
}

.custom-check input:checked + span::after {
	opacity: 1;
}

.custom-check input[type='radio'] + span::before,
.custom-check input[type='radio'] + span::after {
	border-radius: 50%;
}

.custom-check > span {
	position: relative;
	display: inline-block;
	padding-left: 32px;
	color: #494949;
}

.custom-check > span::before,
.custom-check > span::after {
	content: '';
	position: absolute;
	display: block;
	transition: .15s ease;
}

.custom-check > span::before {
	top: 0;
	left: 0;
	width: 22px;
	height: 22px;
	border: 1px solid currentColor;
}

.custom-check > span::after {
	top: 6px;
	left: 6px;
	width: 12px;
	height: 12px;
	background: currentColor;
	opacity: 0;
}

.custom-check-block {
	margin: 20px 0;
}

.custom-check-inline {
	display: flex;
	flex-wrap: wrap;
	margin: 20px -12px 10px;
}

.custom-check-inline .custom-check {
	padding: 0 12px;
	margin-bottom: 10px;
}

.custom-check-inline .custom-check + .custom-check {
	margin-top: 0;
}

.pic-block {
	margin-top: 38px;
	margin-bottom: 33px;
}

.pic-block p:last-child {
	margin-bottom: 0;
}

.pic {
	margin-bottom: 30px;
}

.pic img {
	border-radius: 5px;
}

.card {
	display: flex;
	height: 100%;
	padding: 25px;
	box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
	border-radius: 5px;
	font-size: 14px;
	line-height: 1.57143;
	transform: translateY(0);
	transition: .25s ease-in-out;
}

.card .svg {
	flex-shrink: 0;
}

.card ul > li {
	margin-bottom: 1em;
}

.card__icon {
	display: flex;
	justify-content: center;
	align-items: flex-start;
	width: 40px;
	flex-shrink: 0;
	margin-right: 20px;
}

.card p:last-child {
	margin-bottom: 0;
}

.accordion__card {
	margin-bottom: 15px;
}

.accordion__card:last-child {
	margin-bottom: 0;
}

.accordion__head {
	position: relative;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	height: auto;
	min-height: 40px;
	padding: 10px 20px;
	line-height: 1.3;
	letter-spacing: normal;
	text-transform: none;
	text-align: left;
	font-weight: bold;
	color: #fff;
	background: #378ADF;
	white-space: normal;
}

.accordion__head,
.accordion__head.btn {
	font-size: 15px;
}

.accordion__head::after {
	content: '';
	flex-shrink: 0;
	display: block;
	width: 10px;
	height: 10px;
	margin-left: 20px;
	border: 1px solid #fff;
	border-top: none;
	border-left: none;
	transform: rotate(45deg);
}

.accordion__body p:last-child,
.accordion__body ul:last-child,
.accordion__body li:last-child {
	margin-bottom: 0;
}

.accordion__body p {
	margin-bottom: 1em;
}

.accordion__body-inner {
	padding: 25px 20px 10px;
}

.bg {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	z-index: 1;
}

.bg-wrapper {
	position: absolute;
}

.header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: #fff;
	box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
	z-index: 20;
}

.header__inner {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 70px;
}

.header__item {
	display: flex;
	align-items: center;
	flex-shrink: 0;
}

.header__item:last-child {
	text-align: right;
	justify-content: flex-end;
}

.logo img {
	height: 50px;
	width: auto;
}

.menu {
	display: none;
	flex-grow: 1;
	height: 100%;
}

.navigation .menu {
	display: block;
}

.navigation .menu__list {
	display: block;
	height: auto;
}

.navigation .menu__el {
	display: block;
	height: auto;
}

.navigation .menu__link {
	height: auto;
	padding: 15px 20px;
	font-size: 22px;
	font-weight: normal;
}

.menu__list {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0;
	height: 100%;
}

.menu__el {
	display: inline-block;
	height: 100%;
	margin: 0;
	padding: 0;
}

.menu__el::before {
	display: none;
}

.menu__link {
	display: flex;
	align-items: center;
	height: 100%;
	padding: 0 12px;
	font-size: 17px;
	font-weight: bold;
	color: #000;
	text-decoration: none;
}

.menu a:hover {
	color: #1970C9;
}

.banner {
	position: relative;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	color: #fff;
}

.banner::before {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	display: block;
	background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}

.banner__inner {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	height: 580px;
	max-height: calc(100vh - 70px);
}

.banner .h1 {
	margin: 0 0 .68em;
}

.banner__text {
	margin-bottom: 2.7rem;
}

.banner .btn {
	margin-right: 15px;
	margin-bottom: 10px;
}

.profit__title {
	margin-bottom: .65em;
}

.profit__row {
	align-items: center;
}

.profit__col--left {
	flex: 0 0 50%;
	max-width: 50%;
	padding-right: 50px;
	margin-top: -6px;
}

.profit__col--right {
	flex: 0 0 50%;
	max-width: 50%;
	position: relative;
}

.profit__list {
	padding: 23px 23px 23px;
	position: absolute;
	left: 25px;
	right: 25px;
	bottom: 22px;
	border-radius: 5px;
	background: #5AB8B3;
}

.profit__list ol {
	margin-bottom: 0;
	font-size: 18px;
}

.profit__list ol > li {
	margin-bottom: 0.34em;
	color: #fff;
}

.profit__list ol > li:last-child {
	margin-bottom: 0;
}

.profit__list ol > li:before {
	margin-right: 2px;
	color: #FFD7AD;
}

.profit ul {
	margin-bottom: 2em;
}

.profit ul > li {
	margin-bottom: .58em;
}

.profit ul > li:before {
	top: .5em;
}

.profit-item {
	padding: 15px 30px 8px;
	margin-bottom: 30px;
	background: #fff;
	box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
}

.profit-item__row {
	align-items: center;
}

.profit-item__col--pic {
	flex: 0 0 70px;
	max-width: 70px;
}

.profit-item__text {
	margin-top: -8px;
	font-size: 18px;
	line-height: 1.5;
}

.advantages {
	font-size: 18px;
	line-height: 1.2;
}

.advantages:last-child {
	margin-bottom: 0;
}

.advantages > li {
	display: block;
	margin-bottom: 1.2em;
}

.advantages > li:last-child {
	margin-bottom: 0;
}

.advantages > li::before {
	text-align: left;
	margin-bottom: 3px;
	color: #1970C9;
}

.advantages__title {
	margin-bottom: 5px;
	font-weight: normal;
	color: #000;
}

.advantages__title:last-child {
	margin-bottom: 0;
}

.advantages .small {
	display: block;
	line-height: 1.57143;
	margin-bottom: 0;
}

.form-section {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	text-align: center;
}

.form-section .form {
	max-width: 920px;
}

.form-section .form {
	width: 100%;
}

.steps {
	max-width: 890px;
	margin: 0 auto;
}

.step {
	position: relative;
	display: flex;
	align-items: flex-start;
	padding-bottom: 45px;
}

.step:nth-child(1) .step__num {
	background: #378ADF;
}

.step:nth-child(2) .step__num {
	background: #1970C9;
}

.step:nth-child(3) .step__num {
	background: #1F4F91;
}

.step:last-child {
	padding-bottom: 0;
}

.step:last-child::before,
.step:last-child::after {
	display: none;
}

.step::before {
	content: '';
	position: absolute;
	top: 0;
	bottom: 0;
	left: 41px;
	display: block;
	width: 1px;
	border-left: 1px dashed #1970C9;
}

.step::after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 38px;
	display: block;
	width: 6px;
	height: 6px;
	border: 1px solid #1970C9;
	border-left: none;
	border-top: none;
	transform: rotate(45deg);
}

.step__num {
	position: relative;
	flex-shrink: 0;
	width: 80px;
	margin-right: 20px;
	padding: 12px 0 13px;
	font-size: 15px;
	line-height: 1.1;
	font-weight: 500;
	text-align: center;
	color: #fff;
}

.files {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 -5px;
	padding: 25px 0 0;
}

.file {
	margin: 0 0 10px;
	padding: 0 5px;
	max-width: 100%;
	width: 100%;
}

.file::before {
	display: none;
}

.file.txt .file__link::before {
	background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)' fill='%23fff'%3E%3Cpath d='M1.25 18.125V1.875c0-.345.28-.625.625-.625h9.375v2.5c0 .69.56 1.25 1.25 1.25H15v1.875h1.25v-2.5a.625.625 0 00-.181-.444l-3.75-3.75A.625.625 0 0011.875 0h-10C.839 0 0 .84 0 1.875v16.25C0 19.161.84 20 1.875 20H6.25v-1.25H1.875a.625.625 0 01-.625-.625z'/%3E%3Cpath d='M12.569 10.344l-.694 1.381-.694-1.381a.625.625 0 00-.556-.344h-1.25v1.25h.863l.937 1.875L10.237 15h-.862v1.25h1.25c.235 0 .45-.134.556-.344l.694-1.381.694 1.381c.106.21.32.343.556.344h1.25V15h-.863l-.937-1.875.938-1.875h.862V10h-1.25a.625.625 0 00-.556.344zM3.75 11.25h1.875v5h1.25v-5H8.75V10h-5v1.25zM15 10v1.25h1.875v5h1.25v-5H20V10h-5z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath fill='%23fff' d='M0 0h20v20H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}

.file.txt .file__link:hover::before {
	background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)' fill='%23fff'%3E%3Cpath d='M1.25 18.125V1.875c0-.345.28-.625.625-.625h9.375v2.5c0 .69.56 1.25 1.25 1.25H15v1.875h1.25v-2.5a.625.625 0 00-.181-.444l-3.75-3.75A.625.625 0 0011.875 0h-10C.839 0 0 .84 0 1.875v16.25C0 19.161.84 20 1.875 20H6.25v-1.25H1.875a.625.625 0 01-.625-.625z'/%3E%3Cpath d='M12.569 10.344l-.694 1.381-.694-1.381a.625.625 0 00-.556-.344h-1.25v1.25h.863l.937 1.875L10.237 15h-.862v1.25h1.25c.235 0 .45-.134.556-.344l.694-1.381.694 1.381c.106.21.32.343.556.344h1.25V15h-.863l-.937-1.875.938-1.875h.862V10h-1.25a.625.625 0 00-.556.344zM3.75 11.25h1.875v5h1.25v-5H8.75V10h-5v1.25zM15 10v1.25h1.875v5h1.25v-5H20V10h-5z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath fill='%23fff' d='M0 0h20v20H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}

.file.pdf .file__link::before {
	background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)' fill='%23fff'%3E%3Cpath d='M14.375 10h-2.5a.625.625 0 00-.625.625v5c0 .345.28.625.625.625h2.5c.69 0 1.25-.56 1.25-1.25v-3.75c0-.69-.56-1.25-1.25-1.25zm0 5H12.5v-3.75h1.875V15zM20 11.25V10h-3.125a.625.625 0 00-.625.625v5.625h1.25v-2.5H20V12.5h-2.5v-1.25H20z'/%3E%3Cpath d='M1.25 18.125V1.875c0-.345.28-.625.625-.625h9.375v2.5c0 .69.56 1.25 1.25 1.25H15v1.875h1.25v-2.5a.625.625 0 00-.181-.444l-3.75-3.75A.625.625 0 0011.875 0h-10C.839 0 0 .84 0 1.875v16.25C0 19.161.84 20 1.875 20H6.25v-1.25H1.875a.625.625 0 01-.625-.625z'/%3E%3Cpath d='M9.375 10h-2.5a.625.625 0 00-.625.625v5.625H7.5v-1.875h1.875c.69 0 1.25-.56 1.25-1.25V11.25c0-.69-.56-1.25-1.25-1.25zm0 3.125H7.5V11.25h1.875v1.875z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath fill='%23fff' d='M0 0h20v20H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}

.file.pdf .file__link:hover::before {
	background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)' fill='%23fff'%3E%3Cpath d='M14.375 10h-2.5a.625.625 0 00-.625.625v5c0 .345.28.625.625.625h2.5c.69 0 1.25-.56 1.25-1.25v-3.75c0-.69-.56-1.25-1.25-1.25zm0 5H12.5v-3.75h1.875V15zM20 11.25V10h-3.125a.625.625 0 00-.625.625v5.625h1.25v-2.5H20V12.5h-2.5v-1.25H20z'/%3E%3Cpath d='M1.25 18.125V1.875c0-.345.28-.625.625-.625h9.375v2.5c0 .69.56 1.25 1.25 1.25H15v1.875h1.25v-2.5a.625.625 0 00-.181-.444l-3.75-3.75A.625.625 0 0011.875 0h-10C.839 0 0 .84 0 1.875v16.25C0 19.161.84 20 1.875 20H6.25v-1.25H1.875a.625.625 0 01-.625-.625z'/%3E%3Cpath d='M9.375 10h-2.5a.625.625 0 00-.625.625v5.625H7.5v-1.875h1.875c.69 0 1.25-.56 1.25-1.25V11.25c0-.69-.56-1.25-1.25-1.25zm0 3.125H7.5V11.25h1.875v1.875z'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Cpath fill='%23fff' d='M0 0h20v20H0z'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
}

.file__link {
	display: flex;
	align-items: center;
	padding: 8px 30px 7px;
	min-height: 40px;
	font-size: 11px;
	letter-spacing: 0.15em;
	text-decoration: none;
	text-transform: uppercase;
	color: #fff;
	background: #5AB8B3;
	font-weight: 900;
	transition: .2s ease;
}

.file__link:hover {
	color: #fff;
	background: #E7B075;
}

.file__link::before {
	content: '';
	flex-shrink: 0;
	display: block;
	margin-right: 10px;
	width: 20px;
	height: 20px;
	background-color: transparent;
	background-repeat: no-repeat;
	background-size: contain;
	transition: background .2s;
}

.map-section {
	position: relative;
}

.map-section .container,
.map-section .bg,
.map-section .map-panel-container {
	position: relative;
}

.map {
	height: 50vh;
}

.map-panel {
	width: 100%;
	padding: 30px 0 25px;
	color: #000;
	z-index: 2;
}

.map-panel-container {
	background: #fff;
}

.map-panel .h2 {
	margin-bottom: .43em;
}

.map-panel a {
	text-decoration: underline;
	color: #000;
}

.map-panel a:hover {
	color: #1970C9;
}

.map-panel [href^='tel'] {
	text-decoration: none;
}

.map-panel .contacts-list {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px;
}

.map-panel .contact {
	padding: 0 15px;
	margin: 0 0 15px;
}

.footer {
	padding: 21px 0 22px;
	background: #0D4F94;
}

.footer,
.footer a {
	color: #fff;
}

.hamburger {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 44px;
	height: 44px;
	margin-left: 40px;
	cursor: pointer;
}

.hamburger:hover .hamburger__icon {
	color: #1970C9;
}

.hamburger__icon {
	position: relative;
	margin-top: -15px;
	color: #494949;
	opacity: 1;
	transition: opacity .25s ease;
}

.hamburger__icon,
.hamburger__icon::before,
.hamburger__icon::after {
	display: block;
	width: 32px;
	height: 2px;
	background: currentColor;
}

.hamburger__icon::before,
.hamburger__icon::after {
	content: '';
	position: absolute;
	right: 0;
}

.hamburger__icon::before {
	top: 8px;
	transition: width .25s ease-out;
}

.hamburger__icon::after {
	top: 16px;
}

.navigation {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 100vh;
	width: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	transform: translateX(100%);
	visibility: hidden;
	box-shadow: 0 6px 16px rgba(0, 0, 0, 0.08);
	background: #fff;
	transition: transform .5s 0s ease, visibility 0s .5s;
	z-index: 1006;
}

.navigation_active {
	transform: translateX(0);
	visibility: visible;
	transition: transform .5s 0s ease, visibility 0s 0s;
}

.navigation::-webkit-scrollbar {
	width: 5px;
}

.navigation::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	outline: 1px solid slategrey;
}

.navigation__container {
	padding: 40px 24px 25px;
}

.navigation__footer {
	padding: 0 30px 30px;
}

.navigation-close {
	position: absolute;
	top: 8px;
	right: 8px;
	width: 44px;
	height: 44px;
	cursor: pointer;
}

.navigation-close::before,
.navigation-close::after {
	content: '';
	position: absolute;
	top: 22px;
	left: 7px;
	display: block;
	width: 30px;
	height: 1px;
	background: #000;
	transform: rotate(45deg);
}

.navigation-close::after {
	transform: rotate(-45deg);
}

.navigation-blackout {
	display: none;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	width: 100%;
	background: rgba(0, 0, 0, 0.7);
	z-index: 1005;
}

@media (prefers-reduced-motion: reduce) {

.fade {
	transition: none;
}

.collapsing {
	transition: none;
}

}

@media (min-width: 576px) {

.col-phx {
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
}

.row-cols-phx-1 > * {
	flex: 0 0 100%;
	max-width: 100%;
}

.row-cols-phx-2 > * {
	flex: 0 0 50%;
	max-width: 50%;
}

.row-cols-phx-3 > * {
	flex: 0 0 33.33333%;
	max-width: 33.33333%;
}

.row-cols-phx-4 > * {
	flex: 0 0 25%;
	max-width: 25%;
}

.row-cols-phx-5 > * {
	flex: 0 0 20%;
	max-width: 20%;
}

.row-cols-phx-6 > * {
	flex: 0 0 16.66667%;
	max-width: 16.66667%;
}

.col-phx-auto {
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
}

.col-phx-1 {
	flex: 0 0 8.33333%;
	max-width: 8.33333%;
}

.col-phx-2 {
	flex: 0 0 16.66667%;
	max-width: 16.66667%;
}

.col-phx-3 {
	flex: 0 0 25%;
	max-width: 25%;
}

.col-phx-4 {
	flex: 0 0 33.33333%;
	max-width: 33.33333%;
}

.col-phx-5 {
	flex: 0 0 41.66667%;
	max-width: 41.66667%;
}

.col-phx-6 {
	flex: 0 0 50%;
	max-width: 50%;
}

.col-phx-7 {
	flex: 0 0 58.33333%;
	max-width: 58.33333%;
}

.col-phx-8 {
	flex: 0 0 66.66667%;
	max-width: 66.66667%;
}

.col-phx-9 {
	flex: 0 0 75%;
	max-width: 75%;
}

.col-phx-10 {
	flex: 0 0 83.33333%;
	max-width: 83.33333%;
}

.col-phx-11 {
	flex: 0 0 91.66667%;
	max-width: 91.66667%;
}

.col-phx-12 {
	flex: 0 0 100%;
	max-width: 100%;
}

.order-phx-first {
	order: -1;
}

.order-phx-last {
	order: 13;
}

.order-phx-0 {
	order: 0;
}

.order-phx-1 {
	order: 1;
}

.order-phx-2 {
	order: 2;
}

.order-phx-3 {
	order: 3;
}

.order-phx-4 {
	order: 4;
}

.order-phx-5 {
	order: 5;
}

.order-phx-6 {
	order: 6;
}

.order-phx-7 {
	order: 7;
}

.order-phx-8 {
	order: 8;
}

.order-phx-9 {
	order: 9;
}

.order-phx-10 {
	order: 10;
}

.order-phx-11 {
	order: 11;
}

.order-phx-12 {
	order: 12;
}

.offset-phx-0 {
	margin-left: 0;
}

.offset-phx-1 {
	margin-left: 8.33333%;
}

.offset-phx-2 {
	margin-left: 16.66667%;
}

.offset-phx-3 {
	margin-left: 25%;
}

.offset-phx-4 {
	margin-left: 33.33333%;
}

.offset-phx-5 {
	margin-left: 41.66667%;
}

.offset-phx-6 {
	margin-left: 50%;
}

.offset-phx-7 {
	margin-left: 58.33333%;
}

.offset-phx-8 {
	margin-left: 66.66667%;
}

.offset-phx-9 {
	margin-left: 75%;
}

.offset-phx-10 {
	margin-left: 83.33333%;
}

.offset-phx-11 {
	margin-left: 91.66667%;
}

.d-phx-none {
	display: none !important;
}

.d-phx-inline {
	display: inline !important;
}

.d-phx-inline-block {
	display: inline-block !important;
}

.d-phx-block {
	display: block !important;
}

.d-phx-table {
	display: table !important;
}

.d-phx-table-row {
	display: table-row !important;
}

.d-phx-table-cell {
	display: table-cell !important;
}

.d-phx-flex {
	display: flex !important;
}

.d-phx-inline-flex {
	display: inline-flex !important;
}

.flex-phx-row {
	flex-direction: row !important;
}

.flex-phx-column {
	flex-direction: column !important;
}

.flex-phx-row-reverse {
	flex-direction: row-reverse !important;
}

.flex-phx-column-reverse {
	flex-direction: column-reverse !important;
}

.flex-phx-wrap {
	flex-wrap: wrap !important;
}

.flex-phx-nowrap {
	flex-wrap: nowrap !important;
}

.flex-phx-wrap-reverse {
	flex-wrap: wrap-reverse !important;
}

.flex-phx-fill {
	flex: 1 1 auto !important;
}

.flex-phx-grow-0 {
	flex-grow: 0 !important;
}

.flex-phx-grow-1 {
	flex-grow: 1 !important;
}

.flex-phx-shrink-0 {
	flex-shrink: 0 !important;
}

.flex-phx-shrink-1 {
	flex-shrink: 1 !important;
}

.justify-content-phx-start {
	justify-content: flex-start !important;
}

.justify-content-phx-end {
	justify-content: flex-end !important;
}

.justify-content-phx-center {
	justify-content: center !important;
}

.justify-content-phx-between {
	justify-content: space-between !important;
}

.justify-content-phx-around {
	justify-content: space-around !important;
}

.align-items-phx-start {
	align-items: flex-start !important;
}

.align-items-phx-end {
	align-items: flex-end !important;
}

.align-items-phx-center {
	align-items: center !important;
}

.align-items-phx-baseline {
	align-items: baseline !important;
}

.align-items-phx-stretch {
	align-items: stretch !important;
}

.align-content-phx-start {
	align-content: flex-start !important;
}

.align-content-phx-end {
	align-content: flex-end !important;
}

.align-content-phx-center {
	align-content: center !important;
}

.align-content-phx-between {
	align-content: space-between !important;
}

.align-content-phx-around {
	align-content: space-around !important;
}

.align-content-phx-stretch {
	align-content: stretch !important;
}

.align-self-phx-auto {
	align-self: auto !important;
}

.align-self-phx-start {
	align-self: flex-start !important;
}

.align-self-phx-end {
	align-self: flex-end !important;
}

.align-self-phx-center {
	align-self: center !important;
}

.align-self-phx-baseline {
	align-self: baseline !important;
}

.align-self-phx-stretch {
	align-self: stretch !important;
}

.float-phx-left {
	float: left !important;
}

.float-phx-right {
	float: right !important;
}

.float-phx-none {
	float: none !important;
}

.m-phx-0 {
	margin: 0 !important;
}

.mt-phx-0,
.my-phx-0 {
	margin-top: 0 !important;
}

.mr-phx-0,
.mx-phx-0 {
	margin-right: 0 !important;
}

.mb-phx-0,
.my-phx-0 {
	margin-bottom: 0 !important;
}

.ml-phx-0,
.mx-phx-0 {
	margin-left: 0 !important;
}

.m-phx-1 {
	margin: 1rem !important;
}

.mt-phx-1,
.my-phx-1 {
	margin-top: 1rem !important;
}

.mr-phx-1,
.mx-phx-1 {
	margin-right: 1rem !important;
}

.mb-phx-1,
.my-phx-1 {
	margin-bottom: 1rem !important;
}

.ml-phx-1,
.mx-phx-1 {
	margin-left: 1rem !important;
}

.m-phx-2 {
	margin: 2rem !important;
}

.mt-phx-2,
.my-phx-2 {
	margin-top: 2rem !important;
}

.mr-phx-2,
.mx-phx-2 {
	margin-right: 2rem !important;
}

.mb-phx-2,
.my-phx-2 {
	margin-bottom: 2rem !important;
}

.ml-phx-2,
.mx-phx-2 {
	margin-left: 2rem !important;
}

.m-phx-3 {
	margin: 3rem !important;
}

.mt-phx-3,
.my-phx-3 {
	margin-top: 3rem !important;
}

.mr-phx-3,
.mx-phx-3 {
	margin-right: 3rem !important;
}

.mb-phx-3,
.my-phx-3 {
	margin-bottom: 3rem !important;
}

.ml-phx-3,
.mx-phx-3 {
	margin-left: 3rem !important;
}

.m-phx-4 {
	margin: 4rem !important;
}

.mt-phx-4,
.my-phx-4 {
	margin-top: 4rem !important;
}

.mr-phx-4,
.mx-phx-4 {
	margin-right: 4rem !important;
}

.mb-phx-4,
.my-phx-4 {
	margin-bottom: 4rem !important;
}

.ml-phx-4,
.mx-phx-4 {
	margin-left: 4rem !important;
}

.m-phx-5 {
	margin: 5rem !important;
}

.mt-phx-5,
.my-phx-5 {
	margin-top: 5rem !important;
}

.mr-phx-5,
.mx-phx-5 {
	margin-right: 5rem !important;
}

.mb-phx-5,
.my-phx-5 {
	margin-bottom: 5rem !important;
}

.ml-phx-5,
.mx-phx-5 {
	margin-left: 5rem !important;
}

.m-phx-6 {
	margin: 6rem !important;
}

.mt-phx-6,
.my-phx-6 {
	margin-top: 6rem !important;
}

.mr-phx-6,
.mx-phx-6 {
	margin-right: 6rem !important;
}

.mb-phx-6,
.my-phx-6 {
	margin-bottom: 6rem !important;
}

.ml-phx-6,
.mx-phx-6 {
	margin-left: 6rem !important;
}

.p-phx-0 {
	padding: 0 !important;
}

.pt-phx-0,
.py-phx-0 {
	padding-top: 0 !important;
}

.pr-phx-0,
.px-phx-0 {
	padding-right: 0 !important;
}

.pb-phx-0,
.py-phx-0 {
	padding-bottom: 0 !important;
}

.pl-phx-0,
.px-phx-0 {
	padding-left: 0 !important;
}

.p-phx-1 {
	padding: 1rem !important;
}

.pt-phx-1,
.py-phx-1 {
	padding-top: 1rem !important;
}

.pr-phx-1,
.px-phx-1 {
	padding-right: 1rem !important;
}

.pb-phx-1,
.py-phx-1 {
	padding-bottom: 1rem !important;
}

.pl-phx-1,
.px-phx-1 {
	padding-left: 1rem !important;
}

.p-phx-2 {
	padding: 2rem !important;
}

.pt-phx-2,
.py-phx-2 {
	padding-top: 2rem !important;
}

.pr-phx-2,
.px-phx-2 {
	padding-right: 2rem !important;
}

.pb-phx-2,
.py-phx-2 {
	padding-bottom: 2rem !important;
}

.pl-phx-2,
.px-phx-2 {
	padding-left: 2rem !important;
}

.p-phx-3 {
	padding: 3rem !important;
}

.pt-phx-3,
.py-phx-3 {
	padding-top: 3rem !important;
}

.pr-phx-3,
.px-phx-3 {
	padding-right: 3rem !important;
}

.pb-phx-3,
.py-phx-3 {
	padding-bottom: 3rem !important;
}

.pl-phx-3,
.px-phx-3 {
	padding-left: 3rem !important;
}

.p-phx-4 {
	padding: 4rem !important;
}

.pt-phx-4,
.py-phx-4 {
	padding-top: 4rem !important;
}

.pr-phx-4,
.px-phx-4 {
	padding-right: 4rem !important;
}

.pb-phx-4,
.py-phx-4 {
	padding-bottom: 4rem !important;
}

.pl-phx-4,
.px-phx-4 {
	padding-left: 4rem !important;
}

.p-phx-5 {
	padding: 5rem !important;
}

.pt-phx-5,
.py-phx-5 {
	padding-top: 5rem !important;
}

.pr-phx-5,
.px-phx-5 {
	padding-right: 5rem !important;
}

.pb-phx-5,
.py-phx-5 {
	padding-bottom: 5rem !important;
}

.pl-phx-5,
.px-phx-5 {
	padding-left: 5rem !important;
}

.p-phx-6 {
	padding: 6rem !important;
}

.pt-phx-6,
.py-phx-6 {
	padding-top: 6rem !important;
}

.pr-phx-6,
.px-phx-6 {
	padding-right: 6rem !important;
}

.pb-phx-6,
.py-phx-6 {
	padding-bottom: 6rem !important;
}

.pl-phx-6,
.px-phx-6 {
	padding-left: 6rem !important;
}

.m-phx-n1 {
	margin: -1rem !important;
}

.mt-phx-n1,
.my-phx-n1 {
	margin-top: -1rem !important;
}

.mr-phx-n1,
.mx-phx-n1 {
	margin-right: -1rem !important;
}

.mb-phx-n1,
.my-phx-n1 {
	margin-bottom: -1rem !important;
}

.ml-phx-n1,
.mx-phx-n1 {
	margin-left: -1rem !important;
}

.m-phx-n2 {
	margin: -2rem !important;
}

.mt-phx-n2,
.my-phx-n2 {
	margin-top: -2rem !important;
}

.mr-phx-n2,
.mx-phx-n2 {
	margin-right: -2rem !important;
}

.mb-phx-n2,
.my-phx-n2 {
	margin-bottom: -2rem !important;
}

.ml-phx-n2,
.mx-phx-n2 {
	margin-left: -2rem !important;
}

.m-phx-n3 {
	margin: -3rem !important;
}

.mt-phx-n3,
.my-phx-n3 {
	margin-top: -3rem !important;
}

.mr-phx-n3,
.mx-phx-n3 {
	margin-right: -3rem !important;
}

.mb-phx-n3,
.my-phx-n3 {
	margin-bottom: -3rem !important;
}

.ml-phx-n3,
.mx-phx-n3 {
	margin-left: -3rem !important;
}

.m-phx-n4 {
	margin: -4rem !important;
}

.mt-phx-n4,
.my-phx-n4 {
	margin-top: -4rem !important;
}

.mr-phx-n4,
.mx-phx-n4 {
	margin-right: -4rem !important;
}

.mb-phx-n4,
.my-phx-n4 {
	margin-bottom: -4rem !important;
}

.ml-phx-n4,
.mx-phx-n4 {
	margin-left: -4rem !important;
}

.m-phx-n5 {
	margin: -5rem !important;
}

.mt-phx-n5,
.my-phx-n5 {
	margin-top: -5rem !important;
}

.mr-phx-n5,
.mx-phx-n5 {
	margin-right: -5rem !important;
}

.mb-phx-n5,
.my-phx-n5 {
	margin-bottom: -5rem !important;
}

.ml-phx-n5,
.mx-phx-n5 {
	margin-left: -5rem !important;
}

.m-phx-n6 {
	margin: -6rem !important;
}

.mt-phx-n6,
.my-phx-n6 {
	margin-top: -6rem !important;
}

.mr-phx-n6,
.mx-phx-n6 {
	margin-right: -6rem !important;
}

.mb-phx-n6,
.my-phx-n6 {
	margin-bottom: -6rem !important;
}

.ml-phx-n6,
.mx-phx-n6 {
	margin-left: -6rem !important;
}

.m-phx-auto {
	margin: auto !important;
}

.mt-phx-auto,
.my-phx-auto {
	margin-top: auto !important;
}

.mr-phx-auto,
.mx-phx-auto {
	margin-right: auto !important;
}

.mb-phx-auto,
.my-phx-auto {
	margin-bottom: auto !important;
}

.ml-phx-auto,
.mx-phx-auto {
	margin-left: auto !important;
}

.text-phx-left {
	text-align: left !important;
}

.text-phx-right {
	text-align: right !important;
}

.text-phx-center {
	text-align: center !important;
}

.block-info {
	display: flex;
	align-items: flex-start;
}

.block-info p {
	padding-top: 14px;
}

.file {
	width: auto;
}

.navigation {
	width: 400px;
}

.navigation__container {
	padding: 60px 30px 30px;
}

}

@media (min-width: 768px) {

.col-tb {
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
}

.row-cols-tb-1 > * {
	flex: 0 0 100%;
	max-width: 100%;
}

.row-cols-tb-2 > * {
	flex: 0 0 50%;
	max-width: 50%;
}

.row-cols-tb-3 > * {
	flex: 0 0 33.33333%;
	max-width: 33.33333%;
}

.row-cols-tb-4 > * {
	flex: 0 0 25%;
	max-width: 25%;
}

.row-cols-tb-5 > * {
	flex: 0 0 20%;
	max-width: 20%;
}

.row-cols-tb-6 > * {
	flex: 0 0 16.66667%;
	max-width: 16.66667%;
}

.col-tb-auto {
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
}

.col-tb-1 {
	flex: 0 0 8.33333%;
	max-width: 8.33333%;
}

.col-tb-2 {
	flex: 0 0 16.66667%;
	max-width: 16.66667%;
}

.col-tb-3 {
	flex: 0 0 25%;
	max-width: 25%;
}

.col-tb-4 {
	flex: 0 0 33.33333%;
	max-width: 33.33333%;
}

.col-tb-5 {
	flex: 0 0 41.66667%;
	max-width: 41.66667%;
}

.col-tb-6 {
	flex: 0 0 50%;
	max-width: 50%;
}

.col-tb-7 {
	flex: 0 0 58.33333%;
	max-width: 58.33333%;
}

.col-tb-8 {
	flex: 0 0 66.66667%;
	max-width: 66.66667%;
}

.col-tb-9 {
	flex: 0 0 75%;
	max-width: 75%;
}

.col-tb-10 {
	flex: 0 0 83.33333%;
	max-width: 83.33333%;
}

.col-tb-11 {
	flex: 0 0 91.66667%;
	max-width: 91.66667%;
}

.col-tb-12 {
	flex: 0 0 100%;
	max-width: 100%;
}

.order-tb-first {
	order: -1;
}

.order-tb-last {
	order: 13;
}

.order-tb-0 {
	order: 0;
}

.order-tb-1 {
	order: 1;
}

.order-tb-2 {
	order: 2;
}

.order-tb-3 {
	order: 3;
}

.order-tb-4 {
	order: 4;
}

.order-tb-5 {
	order: 5;
}

.order-tb-6 {
	order: 6;
}

.order-tb-7 {
	order: 7;
}

.order-tb-8 {
	order: 8;
}

.order-tb-9 {
	order: 9;
}

.order-tb-10 {
	order: 10;
}

.order-tb-11 {
	order: 11;
}

.order-tb-12 {
	order: 12;
}

.offset-tb-0 {
	margin-left: 0;
}

.offset-tb-1 {
	margin-left: 8.33333%;
}

.offset-tb-2 {
	margin-left: 16.66667%;
}

.offset-tb-3 {
	margin-left: 25%;
}

.offset-tb-4 {
	margin-left: 33.33333%;
}

.offset-tb-5 {
	margin-left: 41.66667%;
}

.offset-tb-6 {
	margin-left: 50%;
}

.offset-tb-7 {
	margin-left: 58.33333%;
}

.offset-tb-8 {
	margin-left: 66.66667%;
}

.offset-tb-9 {
	margin-left: 75%;
}

.offset-tb-10 {
	margin-left: 83.33333%;
}

.offset-tb-11 {
	margin-left: 91.66667%;
}

.d-tb-none {
	display: none !important;
}

.d-tb-inline {
	display: inline !important;
}

.d-tb-inline-block {
	display: inline-block !important;
}

.d-tb-block {
	display: block !important;
}

.d-tb-table {
	display: table !important;
}

.d-tb-table-row {
	display: table-row !important;
}

.d-tb-table-cell {
	display: table-cell !important;
}

.d-tb-flex {
	display: flex !important;
}

.d-tb-inline-flex {
	display: inline-flex !important;
}

.flex-tb-row {
	flex-direction: row !important;
}

.flex-tb-column {
	flex-direction: column !important;
}

.flex-tb-row-reverse {
	flex-direction: row-reverse !important;
}

.flex-tb-column-reverse {
	flex-direction: column-reverse !important;
}

.flex-tb-wrap {
	flex-wrap: wrap !important;
}

.flex-tb-nowrap {
	flex-wrap: nowrap !important;
}

.flex-tb-wrap-reverse {
	flex-wrap: wrap-reverse !important;
}

.flex-tb-fill {
	flex: 1 1 auto !important;
}

.flex-tb-grow-0 {
	flex-grow: 0 !important;
}

.flex-tb-grow-1 {
	flex-grow: 1 !important;
}

.flex-tb-shrink-0 {
	flex-shrink: 0 !important;
}

.flex-tb-shrink-1 {
	flex-shrink: 1 !important;
}

.justify-content-tb-start {
	justify-content: flex-start !important;
}

.justify-content-tb-end {
	justify-content: flex-end !important;
}

.justify-content-tb-center {
	justify-content: center !important;
}

.justify-content-tb-between {
	justify-content: space-between !important;
}

.justify-content-tb-around {
	justify-content: space-around !important;
}

.align-items-tb-start {
	align-items: flex-start !important;
}

.align-items-tb-end {
	align-items: flex-end !important;
}

.align-items-tb-center {
	align-items: center !important;
}

.align-items-tb-baseline {
	align-items: baseline !important;
}

.align-items-tb-stretch {
	align-items: stretch !important;
}

.align-content-tb-start {
	align-content: flex-start !important;
}

.align-content-tb-end {
	align-content: flex-end !important;
}

.align-content-tb-center {
	align-content: center !important;
}

.align-content-tb-between {
	align-content: space-between !important;
}

.align-content-tb-around {
	align-content: space-around !important;
}

.align-content-tb-stretch {
	align-content: stretch !important;
}

.align-self-tb-auto {
	align-self: auto !important;
}

.align-self-tb-start {
	align-self: flex-start !important;
}

.align-self-tb-end {
	align-self: flex-end !important;
}

.align-self-tb-center {
	align-self: center !important;
}

.align-self-tb-baseline {
	align-self: baseline !important;
}

.align-self-tb-stretch {
	align-self: stretch !important;
}

.float-tb-left {
	float: left !important;
}

.float-tb-right {
	float: right !important;
}

.float-tb-none {
	float: none !important;
}

.m-tb-0 {
	margin: 0 !important;
}

.mt-tb-0,
.my-tb-0 {
	margin-top: 0 !important;
}

.mr-tb-0,
.mx-tb-0 {
	margin-right: 0 !important;
}

.mb-tb-0,
.my-tb-0 {
	margin-bottom: 0 !important;
}

.ml-tb-0,
.mx-tb-0 {
	margin-left: 0 !important;
}

.m-tb-1 {
	margin: 1rem !important;
}

.mt-tb-1,
.my-tb-1 {
	margin-top: 1rem !important;
}

.mr-tb-1,
.mx-tb-1 {
	margin-right: 1rem !important;
}

.mb-tb-1,
.my-tb-1 {
	margin-bottom: 1rem !important;
}

.ml-tb-1,
.mx-tb-1 {
	margin-left: 1rem !important;
}

.m-tb-2 {
	margin: 2rem !important;
}

.mt-tb-2,
.my-tb-2 {
	margin-top: 2rem !important;
}

.mr-tb-2,
.mx-tb-2 {
	margin-right: 2rem !important;
}

.mb-tb-2,
.my-tb-2 {
	margin-bottom: 2rem !important;
}

.ml-tb-2,
.mx-tb-2 {
	margin-left: 2rem !important;
}

.m-tb-3 {
	margin: 3rem !important;
}

.mt-tb-3,
.my-tb-3 {
	margin-top: 3rem !important;
}

.mr-tb-3,
.mx-tb-3 {
	margin-right: 3rem !important;
}

.mb-tb-3,
.my-tb-3 {
	margin-bottom: 3rem !important;
}

.ml-tb-3,
.mx-tb-3 {
	margin-left: 3rem !important;
}

.m-tb-4 {
	margin: 4rem !important;
}

.mt-tb-4,
.my-tb-4 {
	margin-top: 4rem !important;
}

.mr-tb-4,
.mx-tb-4 {
	margin-right: 4rem !important;
}

.mb-tb-4,
.my-tb-4 {
	margin-bottom: 4rem !important;
}

.ml-tb-4,
.mx-tb-4 {
	margin-left: 4rem !important;
}

.m-tb-5 {
	margin: 5rem !important;
}

.mt-tb-5,
.my-tb-5 {
	margin-top: 5rem !important;
}

.mr-tb-5,
.mx-tb-5 {
	margin-right: 5rem !important;
}

.mb-tb-5,
.my-tb-5 {
	margin-bottom: 5rem !important;
}

.ml-tb-5,
.mx-tb-5 {
	margin-left: 5rem !important;
}

.m-tb-6 {
	margin: 6rem !important;
}

.mt-tb-6,
.my-tb-6 {
	margin-top: 6rem !important;
}

.mr-tb-6,
.mx-tb-6 {
	margin-right: 6rem !important;
}

.mb-tb-6,
.my-tb-6 {
	margin-bottom: 6rem !important;
}

.ml-tb-6,
.mx-tb-6 {
	margin-left: 6rem !important;
}

.p-tb-0 {
	padding: 0 !important;
}

.pt-tb-0,
.py-tb-0 {
	padding-top: 0 !important;
}

.pr-tb-0,
.px-tb-0 {
	padding-right: 0 !important;
}

.pb-tb-0,
.py-tb-0 {
	padding-bottom: 0 !important;
}

.pl-tb-0,
.px-tb-0 {
	padding-left: 0 !important;
}

.p-tb-1 {
	padding: 1rem !important;
}

.pt-tb-1,
.py-tb-1 {
	padding-top: 1rem !important;
}

.pr-tb-1,
.px-tb-1 {
	padding-right: 1rem !important;
}

.pb-tb-1,
.py-tb-1 {
	padding-bottom: 1rem !important;
}

.pl-tb-1,
.px-tb-1 {
	padding-left: 1rem !important;
}

.p-tb-2 {
	padding: 2rem !important;
}

.pt-tb-2,
.py-tb-2 {
	padding-top: 2rem !important;
}

.pr-tb-2,
.px-tb-2 {
	padding-right: 2rem !important;
}

.pb-tb-2,
.py-tb-2 {
	padding-bottom: 2rem !important;
}

.pl-tb-2,
.px-tb-2 {
	padding-left: 2rem !important;
}

.p-tb-3 {
	padding: 3rem !important;
}

.pt-tb-3,
.py-tb-3 {
	padding-top: 3rem !important;
}

.pr-tb-3,
.px-tb-3 {
	padding-right: 3rem !important;
}

.pb-tb-3,
.py-tb-3 {
	padding-bottom: 3rem !important;
}

.pl-tb-3,
.px-tb-3 {
	padding-left: 3rem !important;
}

.p-tb-4 {
	padding: 4rem !important;
}

.pt-tb-4,
.py-tb-4 {
	padding-top: 4rem !important;
}

.pr-tb-4,
.px-tb-4 {
	padding-right: 4rem !important;
}

.pb-tb-4,
.py-tb-4 {
	padding-bottom: 4rem !important;
}

.pl-tb-4,
.px-tb-4 {
	padding-left: 4rem !important;
}

.p-tb-5 {
	padding: 5rem !important;
}

.pt-tb-5,
.py-tb-5 {
	padding-top: 5rem !important;
}

.pr-tb-5,
.px-tb-5 {
	padding-right: 5rem !important;
}

.pb-tb-5,
.py-tb-5 {
	padding-bottom: 5rem !important;
}

.pl-tb-5,
.px-tb-5 {
	padding-left: 5rem !important;
}

.p-tb-6 {
	padding: 6rem !important;
}

.pt-tb-6,
.py-tb-6 {
	padding-top: 6rem !important;
}

.pr-tb-6,
.px-tb-6 {
	padding-right: 6rem !important;
}

.pb-tb-6,
.py-tb-6 {
	padding-bottom: 6rem !important;
}

.pl-tb-6,
.px-tb-6 {
	padding-left: 6rem !important;
}

.m-tb-n1 {
	margin: -1rem !important;
}

.mt-tb-n1,
.my-tb-n1 {
	margin-top: -1rem !important;
}

.mr-tb-n1,
.mx-tb-n1 {
	margin-right: -1rem !important;
}

.mb-tb-n1,
.my-tb-n1 {
	margin-bottom: -1rem !important;
}

.ml-tb-n1,
.mx-tb-n1 {
	margin-left: -1rem !important;
}

.m-tb-n2 {
	margin: -2rem !important;
}

.mt-tb-n2,
.my-tb-n2 {
	margin-top: -2rem !important;
}

.mr-tb-n2,
.mx-tb-n2 {
	margin-right: -2rem !important;
}

.mb-tb-n2,
.my-tb-n2 {
	margin-bottom: -2rem !important;
}

.ml-tb-n2,
.mx-tb-n2 {
	margin-left: -2rem !important;
}

.m-tb-n3 {
	margin: -3rem !important;
}

.mt-tb-n3,
.my-tb-n3 {
	margin-top: -3rem !important;
}

.mr-tb-n3,
.mx-tb-n3 {
	margin-right: -3rem !important;
}

.mb-tb-n3,
.my-tb-n3 {
	margin-bottom: -3rem !important;
}

.ml-tb-n3,
.mx-tb-n3 {
	margin-left: -3rem !important;
}

.m-tb-n4 {
	margin: -4rem !important;
}

.mt-tb-n4,
.my-tb-n4 {
	margin-top: -4rem !important;
}

.mr-tb-n4,
.mx-tb-n4 {
	margin-right: -4rem !important;
}

.mb-tb-n4,
.my-tb-n4 {
	margin-bottom: -4rem !important;
}

.ml-tb-n4,
.mx-tb-n4 {
	margin-left: -4rem !important;
}

.m-tb-n5 {
	margin: -5rem !important;
}

.mt-tb-n5,
.my-tb-n5 {
	margin-top: -5rem !important;
}

.mr-tb-n5,
.mx-tb-n5 {
	margin-right: -5rem !important;
}

.mb-tb-n5,
.my-tb-n5 {
	margin-bottom: -5rem !important;
}

.ml-tb-n5,
.mx-tb-n5 {
	margin-left: -5rem !important;
}

.m-tb-n6 {
	margin: -6rem !important;
}

.mt-tb-n6,
.my-tb-n6 {
	margin-top: -6rem !important;
}

.mr-tb-n6,
.mx-tb-n6 {
	margin-right: -6rem !important;
}

.mb-tb-n6,
.my-tb-n6 {
	margin-bottom: -6rem !important;
}

.ml-tb-n6,
.mx-tb-n6 {
	margin-left: -6rem !important;
}

.m-tb-auto {
	margin: auto !important;
}

.mt-tb-auto,
.my-tb-auto {
	margin-top: auto !important;
}

.mr-tb-auto,
.mx-tb-auto {
	margin-right: auto !important;
}

.mb-tb-auto,
.my-tb-auto {
	margin-bottom: auto !important;
}

.ml-tb-auto,
.mx-tb-auto {
	margin-left: auto !important;
}

.text-tb-left {
	text-align: left !important;
}

.text-tb-right {
	text-align: right !important;
}

.text-tb-center {
	text-align: center !important;
}

.page {
	padding-top: 110px;
}

.container,
.bg,
.map-panel-container {
	padding: 0 30px;
}

.section-head {
	display: flex;
	align-items: baseline;
	margin-bottom: 1rem;
}

.section-head .link {
	flex-shrink: 0;
	white-space: nowrap;
	margin-left: 30px;
	margin-top: 0;
}

.block-info {
	font-size: 17px;
}

.block-info__pic {
	margin-bottom: 0;
}

.card:hover {
	transform: translateY(-10px);
}

.card__icon {
	width: 50px;
}

.accordion__card {
	margin-bottom: 30px;
}

.accordion__head {
	min-height: 60px;
	padding: 15px 33px 15px 29px;
}

.accordion__head,
.accordion__head.btn {
	font-size: 20px;
}

.accordion__head::after {
	width: 12px;
	height: 12px;
	margin-top: 4px;
}

.accordion__body-inner {
	padding: 25px 30px 0;
}

.header__inner {
	height: 110px;
}

.header__item {
	width: 18.7%;
}

.logo img {
	height: 80px;
}

.banner {
	font-size: 18px;
	line-height: 1.55;
}

.banner__inner {
	max-height: calc(100vh - 110px);
	padding-right: 30%;
}

.advantages {
	font-size: 20px;
	line-height: 1.3;
}

.advantages > li::before {
	float: left;
	min-width: 32px;
	margin-right: 5px;
	margin-bottom: 0;
}

.step::before {
	left: 98px;
}

.step::after {
	left: 95px;
}

.step__num {
	width: 194px;
	margin-right: 30px;
	padding: 20px 0 22px;
	font-size: 35px;
}

.map {
	height: 550px;
}

.footer {
	font-size: 16px;
}

}

@media (min-width: 992px) {

.col-tbx {
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
}

.row-cols-tbx-1 > * {
	flex: 0 0 100%;
	max-width: 100%;
}

.row-cols-tbx-2 > * {
	flex: 0 0 50%;
	max-width: 50%;
}

.row-cols-tbx-3 > * {
	flex: 0 0 33.33333%;
	max-width: 33.33333%;
}

.row-cols-tbx-4 > * {
	flex: 0 0 25%;
	max-width: 25%;
}

.row-cols-tbx-5 > * {
	flex: 0 0 20%;
	max-width: 20%;
}

.row-cols-tbx-6 > * {
	flex: 0 0 16.66667%;
	max-width: 16.66667%;
}

.col-tbx-auto {
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
}

.col-tbx-1 {
	flex: 0 0 8.33333%;
	max-width: 8.33333%;
}

.col-tbx-2 {
	flex: 0 0 16.66667%;
	max-width: 16.66667%;
}

.col-tbx-3 {
	flex: 0 0 25%;
	max-width: 25%;
}

.col-tbx-4 {
	flex: 0 0 33.33333%;
	max-width: 33.33333%;
}

.col-tbx-5 {
	flex: 0 0 41.66667%;
	max-width: 41.66667%;
}

.col-tbx-6 {
	flex: 0 0 50%;
	max-width: 50%;
}

.col-tbx-7 {
	flex: 0 0 58.33333%;
	max-width: 58.33333%;
}

.col-tbx-8 {
	flex: 0 0 66.66667%;
	max-width: 66.66667%;
}

.col-tbx-9 {
	flex: 0 0 75%;
	max-width: 75%;
}

.col-tbx-10 {
	flex: 0 0 83.33333%;
	max-width: 83.33333%;
}

.col-tbx-11 {
	flex: 0 0 91.66667%;
	max-width: 91.66667%;
}

.col-tbx-12 {
	flex: 0 0 100%;
	max-width: 100%;
}

.order-tbx-first {
	order: -1;
}

.order-tbx-last {
	order: 13;
}

.order-tbx-0 {
	order: 0;
}

.order-tbx-1 {
	order: 1;
}

.order-tbx-2 {
	order: 2;
}

.order-tbx-3 {
	order: 3;
}

.order-tbx-4 {
	order: 4;
}

.order-tbx-5 {
	order: 5;
}

.order-tbx-6 {
	order: 6;
}

.order-tbx-7 {
	order: 7;
}

.order-tbx-8 {
	order: 8;
}

.order-tbx-9 {
	order: 9;
}

.order-tbx-10 {
	order: 10;
}

.order-tbx-11 {
	order: 11;
}

.order-tbx-12 {
	order: 12;
}

.offset-tbx-0 {
	margin-left: 0;
}

.offset-tbx-1 {
	margin-left: 8.33333%;
}

.offset-tbx-2 {
	margin-left: 16.66667%;
}

.offset-tbx-3 {
	margin-left: 25%;
}

.offset-tbx-4 {
	margin-left: 33.33333%;
}

.offset-tbx-5 {
	margin-left: 41.66667%;
}

.offset-tbx-6 {
	margin-left: 50%;
}

.offset-tbx-7 {
	margin-left: 58.33333%;
}

.offset-tbx-8 {
	margin-left: 66.66667%;
}

.offset-tbx-9 {
	margin-left: 75%;
}

.offset-tbx-10 {
	margin-left: 83.33333%;
}

.offset-tbx-11 {
	margin-left: 91.66667%;
}

.d-tbx-none {
	display: none !important;
}

.d-tbx-inline {
	display: inline !important;
}

.d-tbx-inline-block {
	display: inline-block !important;
}

.d-tbx-block {
	display: block !important;
}

.d-tbx-table {
	display: table !important;
}

.d-tbx-table-row {
	display: table-row !important;
}

.d-tbx-table-cell {
	display: table-cell !important;
}

.d-tbx-flex {
	display: flex !important;
}

.d-tbx-inline-flex {
	display: inline-flex !important;
}

.flex-tbx-row {
	flex-direction: row !important;
}

.flex-tbx-column {
	flex-direction: column !important;
}

.flex-tbx-row-reverse {
	flex-direction: row-reverse !important;
}

.flex-tbx-column-reverse {
	flex-direction: column-reverse !important;
}

.flex-tbx-wrap {
	flex-wrap: wrap !important;
}

.flex-tbx-nowrap {
	flex-wrap: nowrap !important;
}

.flex-tbx-wrap-reverse {
	flex-wrap: wrap-reverse !important;
}

.flex-tbx-fill {
	flex: 1 1 auto !important;
}

.flex-tbx-grow-0 {
	flex-grow: 0 !important;
}

.flex-tbx-grow-1 {
	flex-grow: 1 !important;
}

.flex-tbx-shrink-0 {
	flex-shrink: 0 !important;
}

.flex-tbx-shrink-1 {
	flex-shrink: 1 !important;
}

.justify-content-tbx-start {
	justify-content: flex-start !important;
}

.justify-content-tbx-end {
	justify-content: flex-end !important;
}

.justify-content-tbx-center {
	justify-content: center !important;
}

.justify-content-tbx-between {
	justify-content: space-between !important;
}

.justify-content-tbx-around {
	justify-content: space-around !important;
}

.align-items-tbx-start {
	align-items: flex-start !important;
}

.align-items-tbx-end {
	align-items: flex-end !important;
}

.align-items-tbx-center {
	align-items: center !important;
}

.align-items-tbx-baseline {
	align-items: baseline !important;
}

.align-items-tbx-stretch {
	align-items: stretch !important;
}

.align-content-tbx-start {
	align-content: flex-start !important;
}

.align-content-tbx-end {
	align-content: flex-end !important;
}

.align-content-tbx-center {
	align-content: center !important;
}

.align-content-tbx-between {
	align-content: space-between !important;
}

.align-content-tbx-around {
	align-content: space-around !important;
}

.align-content-tbx-stretch {
	align-content: stretch !important;
}

.align-self-tbx-auto {
	align-self: auto !important;
}

.align-self-tbx-start {
	align-self: flex-start !important;
}

.align-self-tbx-end {
	align-self: flex-end !important;
}

.align-self-tbx-center {
	align-self: center !important;
}

.align-self-tbx-baseline {
	align-self: baseline !important;
}

.align-self-tbx-stretch {
	align-self: stretch !important;
}

.float-tbx-left {
	float: left !important;
}

.float-tbx-right {
	float: right !important;
}

.float-tbx-none {
	float: none !important;
}

.m-tbx-0 {
	margin: 0 !important;
}

.mt-tbx-0,
.my-tbx-0 {
	margin-top: 0 !important;
}

.mr-tbx-0,
.mx-tbx-0 {
	margin-right: 0 !important;
}

.mb-tbx-0,
.my-tbx-0 {
	margin-bottom: 0 !important;
}

.ml-tbx-0,
.mx-tbx-0 {
	margin-left: 0 !important;
}

.m-tbx-1 {
	margin: 1rem !important;
}

.mt-tbx-1,
.my-tbx-1 {
	margin-top: 1rem !important;
}

.mr-tbx-1,
.mx-tbx-1 {
	margin-right: 1rem !important;
}

.mb-tbx-1,
.my-tbx-1 {
	margin-bottom: 1rem !important;
}

.ml-tbx-1,
.mx-tbx-1 {
	margin-left: 1rem !important;
}

.m-tbx-2 {
	margin: 2rem !important;
}

.mt-tbx-2,
.my-tbx-2 {
	margin-top: 2rem !important;
}

.mr-tbx-2,
.mx-tbx-2 {
	margin-right: 2rem !important;
}

.mb-tbx-2,
.my-tbx-2 {
	margin-bottom: 2rem !important;
}

.ml-tbx-2,
.mx-tbx-2 {
	margin-left: 2rem !important;
}

.m-tbx-3 {
	margin: 3rem !important;
}

.mt-tbx-3,
.my-tbx-3 {
	margin-top: 3rem !important;
}

.mr-tbx-3,
.mx-tbx-3 {
	margin-right: 3rem !important;
}

.mb-tbx-3,
.my-tbx-3 {
	margin-bottom: 3rem !important;
}

.ml-tbx-3,
.mx-tbx-3 {
	margin-left: 3rem !important;
}

.m-tbx-4 {
	margin: 4rem !important;
}

.mt-tbx-4,
.my-tbx-4 {
	margin-top: 4rem !important;
}

.mr-tbx-4,
.mx-tbx-4 {
	margin-right: 4rem !important;
}

.mb-tbx-4,
.my-tbx-4 {
	margin-bottom: 4rem !important;
}

.ml-tbx-4,
.mx-tbx-4 {
	margin-left: 4rem !important;
}

.m-tbx-5 {
	margin: 5rem !important;
}

.mt-tbx-5,
.my-tbx-5 {
	margin-top: 5rem !important;
}

.mr-tbx-5,
.mx-tbx-5 {
	margin-right: 5rem !important;
}

.mb-tbx-5,
.my-tbx-5 {
	margin-bottom: 5rem !important;
}

.ml-tbx-5,
.mx-tbx-5 {
	margin-left: 5rem !important;
}

.m-tbx-6 {
	margin: 6rem !important;
}

.mt-tbx-6,
.my-tbx-6 {
	margin-top: 6rem !important;
}

.mr-tbx-6,
.mx-tbx-6 {
	margin-right: 6rem !important;
}

.mb-tbx-6,
.my-tbx-6 {
	margin-bottom: 6rem !important;
}

.ml-tbx-6,
.mx-tbx-6 {
	margin-left: 6rem !important;
}

.p-tbx-0 {
	padding: 0 !important;
}

.pt-tbx-0,
.py-tbx-0 {
	padding-top: 0 !important;
}

.pr-tbx-0,
.px-tbx-0 {
	padding-right: 0 !important;
}

.pb-tbx-0,
.py-tbx-0 {
	padding-bottom: 0 !important;
}

.pl-tbx-0,
.px-tbx-0 {
	padding-left: 0 !important;
}

.p-tbx-1 {
	padding: 1rem !important;
}

.pt-tbx-1,
.py-tbx-1 {
	padding-top: 1rem !important;
}

.pr-tbx-1,
.px-tbx-1 {
	padding-right: 1rem !important;
}

.pb-tbx-1,
.py-tbx-1 {
	padding-bottom: 1rem !important;
}

.pl-tbx-1,
.px-tbx-1 {
	padding-left: 1rem !important;
}

.p-tbx-2 {
	padding: 2rem !important;
}

.pt-tbx-2,
.py-tbx-2 {
	padding-top: 2rem !important;
}

.pr-tbx-2,
.px-tbx-2 {
	padding-right: 2rem !important;
}

.pb-tbx-2,
.py-tbx-2 {
	padding-bottom: 2rem !important;
}

.pl-tbx-2,
.px-tbx-2 {
	padding-left: 2rem !important;
}

.p-tbx-3 {
	padding: 3rem !important;
}

.pt-tbx-3,
.py-tbx-3 {
	padding-top: 3rem !important;
}

.pr-tbx-3,
.px-tbx-3 {
	padding-right: 3rem !important;
}

.pb-tbx-3,
.py-tbx-3 {
	padding-bottom: 3rem !important;
}

.pl-tbx-3,
.px-tbx-3 {
	padding-left: 3rem !important;
}

.p-tbx-4 {
	padding: 4rem !important;
}

.pt-tbx-4,
.py-tbx-4 {
	padding-top: 4rem !important;
}

.pr-tbx-4,
.px-tbx-4 {
	padding-right: 4rem !important;
}

.pb-tbx-4,
.py-tbx-4 {
	padding-bottom: 4rem !important;
}

.pl-tbx-4,
.px-tbx-4 {
	padding-left: 4rem !important;
}

.p-tbx-5 {
	padding: 5rem !important;
}

.pt-tbx-5,
.py-tbx-5 {
	padding-top: 5rem !important;
}

.pr-tbx-5,
.px-tbx-5 {
	padding-right: 5rem !important;
}

.pb-tbx-5,
.py-tbx-5 {
	padding-bottom: 5rem !important;
}

.pl-tbx-5,
.px-tbx-5 {
	padding-left: 5rem !important;
}

.p-tbx-6 {
	padding: 6rem !important;
}

.pt-tbx-6,
.py-tbx-6 {
	padding-top: 6rem !important;
}

.pr-tbx-6,
.px-tbx-6 {
	padding-right: 6rem !important;
}

.pb-tbx-6,
.py-tbx-6 {
	padding-bottom: 6rem !important;
}

.pl-tbx-6,
.px-tbx-6 {
	padding-left: 6rem !important;
}

.m-tbx-n1 {
	margin: -1rem !important;
}

.mt-tbx-n1,
.my-tbx-n1 {
	margin-top: -1rem !important;
}

.mr-tbx-n1,
.mx-tbx-n1 {
	margin-right: -1rem !important;
}

.mb-tbx-n1,
.my-tbx-n1 {
	margin-bottom: -1rem !important;
}

.ml-tbx-n1,
.mx-tbx-n1 {
	margin-left: -1rem !important;
}

.m-tbx-n2 {
	margin: -2rem !important;
}

.mt-tbx-n2,
.my-tbx-n2 {
	margin-top: -2rem !important;
}

.mr-tbx-n2,
.mx-tbx-n2 {
	margin-right: -2rem !important;
}

.mb-tbx-n2,
.my-tbx-n2 {
	margin-bottom: -2rem !important;
}

.ml-tbx-n2,
.mx-tbx-n2 {
	margin-left: -2rem !important;
}

.m-tbx-n3 {
	margin: -3rem !important;
}

.mt-tbx-n3,
.my-tbx-n3 {
	margin-top: -3rem !important;
}

.mr-tbx-n3,
.mx-tbx-n3 {
	margin-right: -3rem !important;
}

.mb-tbx-n3,
.my-tbx-n3 {
	margin-bottom: -3rem !important;
}

.ml-tbx-n3,
.mx-tbx-n3 {
	margin-left: -3rem !important;
}

.m-tbx-n4 {
	margin: -4rem !important;
}

.mt-tbx-n4,
.my-tbx-n4 {
	margin-top: -4rem !important;
}

.mr-tbx-n4,
.mx-tbx-n4 {
	margin-right: -4rem !important;
}

.mb-tbx-n4,
.my-tbx-n4 {
	margin-bottom: -4rem !important;
}

.ml-tbx-n4,
.mx-tbx-n4 {
	margin-left: -4rem !important;
}

.m-tbx-n5 {
	margin: -5rem !important;
}

.mt-tbx-n5,
.my-tbx-n5 {
	margin-top: -5rem !important;
}

.mr-tbx-n5,
.mx-tbx-n5 {
	margin-right: -5rem !important;
}

.mb-tbx-n5,
.my-tbx-n5 {
	margin-bottom: -5rem !important;
}

.ml-tbx-n5,
.mx-tbx-n5 {
	margin-left: -5rem !important;
}

.m-tbx-n6 {
	margin: -6rem !important;
}

.mt-tbx-n6,
.my-tbx-n6 {
	margin-top: -6rem !important;
}

.mr-tbx-n6,
.mx-tbx-n6 {
	margin-right: -6rem !important;
}

.mb-tbx-n6,
.my-tbx-n6 {
	margin-bottom: -6rem !important;
}

.ml-tbx-n6,
.mx-tbx-n6 {
	margin-left: -6rem !important;
}

.m-tbx-auto {
	margin: auto !important;
}

.mt-tbx-auto,
.my-tbx-auto {
	margin-top: auto !important;
}

.mr-tbx-auto,
.mx-tbx-auto {
	margin-right: auto !important;
}

.mb-tbx-auto,
.my-tbx-auto {
	margin-bottom: auto !important;
}

.ml-tbx-auto,
.mx-tbx-auto {
	margin-left: auto !important;
}

.text-tbx-left {
	text-align: left !important;
}

.text-tbx-right {
	text-align: right !important;
}

.text-tbx-center {
	text-align: center !important;
}

.container,
.bg,
.map-panel-container {
	padding: 0 60px;
}

.pic {
	margin-bottom: 0;
}

.pic img {
	width: 100%;
}

.accordion__head,
.accordion__head.btn {
	font-size: 22px;
}

.step__num {
	font-size: 40px;
}

.map-panel {
	position: absolute;
	top: 124px;
	left: 0;
	width: 420px;
	padding: 50px 40px 35px;
	background: #fff;
	box-shadow: 0 5px 34px rgba(0, 0, 0, 0.35);
}

.map-panel-container {
	background: none;
}

.map-panel .contact {
	flex: 0 0 50%;
	max-width: 50%;
}

}

@media (min-width: 1260px) {

.col-lt {
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
}

.row-cols-lt-1 > * {
	flex: 0 0 100%;
	max-width: 100%;
}

.row-cols-lt-2 > * {
	flex: 0 0 50%;
	max-width: 50%;
}

.row-cols-lt-3 > * {
	flex: 0 0 33.33333%;
	max-width: 33.33333%;
}

.row-cols-lt-4 > * {
	flex: 0 0 25%;
	max-width: 25%;
}

.row-cols-lt-5 > * {
	flex: 0 0 20%;
	max-width: 20%;
}

.row-cols-lt-6 > * {
	flex: 0 0 16.66667%;
	max-width: 16.66667%;
}

.col-lt-auto {
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
}

.col-lt-1 {
	flex: 0 0 8.33333%;
	max-width: 8.33333%;
}

.col-lt-2 {
	flex: 0 0 16.66667%;
	max-width: 16.66667%;
}

.col-lt-3 {
	flex: 0 0 25%;
	max-width: 25%;
}

.col-lt-4 {
	flex: 0 0 33.33333%;
	max-width: 33.33333%;
}

.col-lt-5 {
	flex: 0 0 41.66667%;
	max-width: 41.66667%;
}

.col-lt-6 {
	flex: 0 0 50%;
	max-width: 50%;
}

.col-lt-7 {
	flex: 0 0 58.33333%;
	max-width: 58.33333%;
}

.col-lt-8 {
	flex: 0 0 66.66667%;
	max-width: 66.66667%;
}

.col-lt-9 {
	flex: 0 0 75%;
	max-width: 75%;
}

.col-lt-10 {
	flex: 0 0 83.33333%;
	max-width: 83.33333%;
}

.col-lt-11 {
	flex: 0 0 91.66667%;
	max-width: 91.66667%;
}

.col-lt-12 {
	flex: 0 0 100%;
	max-width: 100%;
}

.order-lt-first {
	order: -1;
}

.order-lt-last {
	order: 13;
}

.order-lt-0 {
	order: 0;
}

.order-lt-1 {
	order: 1;
}

.order-lt-2 {
	order: 2;
}

.order-lt-3 {
	order: 3;
}

.order-lt-4 {
	order: 4;
}

.order-lt-5 {
	order: 5;
}

.order-lt-6 {
	order: 6;
}

.order-lt-7 {
	order: 7;
}

.order-lt-8 {
	order: 8;
}

.order-lt-9 {
	order: 9;
}

.order-lt-10 {
	order: 10;
}

.order-lt-11 {
	order: 11;
}

.order-lt-12 {
	order: 12;
}

.offset-lt-0 {
	margin-left: 0;
}

.offset-lt-1 {
	margin-left: 8.33333%;
}

.offset-lt-2 {
	margin-left: 16.66667%;
}

.offset-lt-3 {
	margin-left: 25%;
}

.offset-lt-4 {
	margin-left: 33.33333%;
}

.offset-lt-5 {
	margin-left: 41.66667%;
}

.offset-lt-6 {
	margin-left: 50%;
}

.offset-lt-7 {
	margin-left: 58.33333%;
}

.offset-lt-8 {
	margin-left: 66.66667%;
}

.offset-lt-9 {
	margin-left: 75%;
}

.offset-lt-10 {
	margin-left: 83.33333%;
}

.offset-lt-11 {
	margin-left: 91.66667%;
}

.d-lt-none {
	display: none !important;
}

.d-lt-inline {
	display: inline !important;
}

.d-lt-inline-block {
	display: inline-block !important;
}

.d-lt-block {
	display: block !important;
}

.d-lt-table {
	display: table !important;
}

.d-lt-table-row {
	display: table-row !important;
}

.d-lt-table-cell {
	display: table-cell !important;
}

.d-lt-flex {
	display: flex !important;
}

.d-lt-inline-flex {
	display: inline-flex !important;
}

.flex-lt-row {
	flex-direction: row !important;
}

.flex-lt-column {
	flex-direction: column !important;
}

.flex-lt-row-reverse {
	flex-direction: row-reverse !important;
}

.flex-lt-column-reverse {
	flex-direction: column-reverse !important;
}

.flex-lt-wrap {
	flex-wrap: wrap !important;
}

.flex-lt-nowrap {
	flex-wrap: nowrap !important;
}

.flex-lt-wrap-reverse {
	flex-wrap: wrap-reverse !important;
}

.flex-lt-fill {
	flex: 1 1 auto !important;
}

.flex-lt-grow-0 {
	flex-grow: 0 !important;
}

.flex-lt-grow-1 {
	flex-grow: 1 !important;
}

.flex-lt-shrink-0 {
	flex-shrink: 0 !important;
}

.flex-lt-shrink-1 {
	flex-shrink: 1 !important;
}

.justify-content-lt-start {
	justify-content: flex-start !important;
}

.justify-content-lt-end {
	justify-content: flex-end !important;
}

.justify-content-lt-center {
	justify-content: center !important;
}

.justify-content-lt-between {
	justify-content: space-between !important;
}

.justify-content-lt-around {
	justify-content: space-around !important;
}

.align-items-lt-start {
	align-items: flex-start !important;
}

.align-items-lt-end {
	align-items: flex-end !important;
}

.align-items-lt-center {
	align-items: center !important;
}

.align-items-lt-baseline {
	align-items: baseline !important;
}

.align-items-lt-stretch {
	align-items: stretch !important;
}

.align-content-lt-start {
	align-content: flex-start !important;
}

.align-content-lt-end {
	align-content: flex-end !important;
}

.align-content-lt-center {
	align-content: center !important;
}

.align-content-lt-between {
	align-content: space-between !important;
}

.align-content-lt-around {
	align-content: space-around !important;
}

.align-content-lt-stretch {
	align-content: stretch !important;
}

.align-self-lt-auto {
	align-self: auto !important;
}

.align-self-lt-start {
	align-self: flex-start !important;
}

.align-self-lt-end {
	align-self: flex-end !important;
}

.align-self-lt-center {
	align-self: center !important;
}

.align-self-lt-baseline {
	align-self: baseline !important;
}

.align-self-lt-stretch {
	align-self: stretch !important;
}

.float-lt-left {
	float: left !important;
}

.float-lt-right {
	float: right !important;
}

.float-lt-none {
	float: none !important;
}

.m-lt-0 {
	margin: 0 !important;
}

.mt-lt-0,
.my-lt-0 {
	margin-top: 0 !important;
}

.mr-lt-0,
.mx-lt-0 {
	margin-right: 0 !important;
}

.mb-lt-0,
.my-lt-0 {
	margin-bottom: 0 !important;
}

.ml-lt-0,
.mx-lt-0 {
	margin-left: 0 !important;
}

.m-lt-1 {
	margin: 1rem !important;
}

.mt-lt-1,
.my-lt-1 {
	margin-top: 1rem !important;
}

.mr-lt-1,
.mx-lt-1 {
	margin-right: 1rem !important;
}

.mb-lt-1,
.my-lt-1 {
	margin-bottom: 1rem !important;
}

.ml-lt-1,
.mx-lt-1 {
	margin-left: 1rem !important;
}

.m-lt-2 {
	margin: 2rem !important;
}

.mt-lt-2,
.my-lt-2 {
	margin-top: 2rem !important;
}

.mr-lt-2,
.mx-lt-2 {
	margin-right: 2rem !important;
}

.mb-lt-2,
.my-lt-2 {
	margin-bottom: 2rem !important;
}

.ml-lt-2,
.mx-lt-2 {
	margin-left: 2rem !important;
}

.m-lt-3 {
	margin: 3rem !important;
}

.mt-lt-3,
.my-lt-3 {
	margin-top: 3rem !important;
}

.mr-lt-3,
.mx-lt-3 {
	margin-right: 3rem !important;
}

.mb-lt-3,
.my-lt-3 {
	margin-bottom: 3rem !important;
}

.ml-lt-3,
.mx-lt-3 {
	margin-left: 3rem !important;
}

.m-lt-4 {
	margin: 4rem !important;
}

.mt-lt-4,
.my-lt-4 {
	margin-top: 4rem !important;
}

.mr-lt-4,
.mx-lt-4 {
	margin-right: 4rem !important;
}

.mb-lt-4,
.my-lt-4 {
	margin-bottom: 4rem !important;
}

.ml-lt-4,
.mx-lt-4 {
	margin-left: 4rem !important;
}

.m-lt-5 {
	margin: 5rem !important;
}

.mt-lt-5,
.my-lt-5 {
	margin-top: 5rem !important;
}

.mr-lt-5,
.mx-lt-5 {
	margin-right: 5rem !important;
}

.mb-lt-5,
.my-lt-5 {
	margin-bottom: 5rem !important;
}

.ml-lt-5,
.mx-lt-5 {
	margin-left: 5rem !important;
}

.m-lt-6 {
	margin: 6rem !important;
}

.mt-lt-6,
.my-lt-6 {
	margin-top: 6rem !important;
}

.mr-lt-6,
.mx-lt-6 {
	margin-right: 6rem !important;
}

.mb-lt-6,
.my-lt-6 {
	margin-bottom: 6rem !important;
}

.ml-lt-6,
.mx-lt-6 {
	margin-left: 6rem !important;
}

.p-lt-0 {
	padding: 0 !important;
}

.pt-lt-0,
.py-lt-0 {
	padding-top: 0 !important;
}

.pr-lt-0,
.px-lt-0 {
	padding-right: 0 !important;
}

.pb-lt-0,
.py-lt-0 {
	padding-bottom: 0 !important;
}

.pl-lt-0,
.px-lt-0 {
	padding-left: 0 !important;
}

.p-lt-1 {
	padding: 1rem !important;
}

.pt-lt-1,
.py-lt-1 {
	padding-top: 1rem !important;
}

.pr-lt-1,
.px-lt-1 {
	padding-right: 1rem !important;
}

.pb-lt-1,
.py-lt-1 {
	padding-bottom: 1rem !important;
}

.pl-lt-1,
.px-lt-1 {
	padding-left: 1rem !important;
}

.p-lt-2 {
	padding: 2rem !important;
}

.pt-lt-2,
.py-lt-2 {
	padding-top: 2rem !important;
}

.pr-lt-2,
.px-lt-2 {
	padding-right: 2rem !important;
}

.pb-lt-2,
.py-lt-2 {
	padding-bottom: 2rem !important;
}

.pl-lt-2,
.px-lt-2 {
	padding-left: 2rem !important;
}

.p-lt-3 {
	padding: 3rem !important;
}

.pt-lt-3,
.py-lt-3 {
	padding-top: 3rem !important;
}

.pr-lt-3,
.px-lt-3 {
	padding-right: 3rem !important;
}

.pb-lt-3,
.py-lt-3 {
	padding-bottom: 3rem !important;
}

.pl-lt-3,
.px-lt-3 {
	padding-left: 3rem !important;
}

.p-lt-4 {
	padding: 4rem !important;
}

.pt-lt-4,
.py-lt-4 {
	padding-top: 4rem !important;
}

.pr-lt-4,
.px-lt-4 {
	padding-right: 4rem !important;
}

.pb-lt-4,
.py-lt-4 {
	padding-bottom: 4rem !important;
}

.pl-lt-4,
.px-lt-4 {
	padding-left: 4rem !important;
}

.p-lt-5 {
	padding: 5rem !important;
}

.pt-lt-5,
.py-lt-5 {
	padding-top: 5rem !important;
}

.pr-lt-5,
.px-lt-5 {
	padding-right: 5rem !important;
}

.pb-lt-5,
.py-lt-5 {
	padding-bottom: 5rem !important;
}

.pl-lt-5,
.px-lt-5 {
	padding-left: 5rem !important;
}

.p-lt-6 {
	padding: 6rem !important;
}

.pt-lt-6,
.py-lt-6 {
	padding-top: 6rem !important;
}

.pr-lt-6,
.px-lt-6 {
	padding-right: 6rem !important;
}

.pb-lt-6,
.py-lt-6 {
	padding-bottom: 6rem !important;
}

.pl-lt-6,
.px-lt-6 {
	padding-left: 6rem !important;
}

.m-lt-n1 {
	margin: -1rem !important;
}

.mt-lt-n1,
.my-lt-n1 {
	margin-top: -1rem !important;
}

.mr-lt-n1,
.mx-lt-n1 {
	margin-right: -1rem !important;
}

.mb-lt-n1,
.my-lt-n1 {
	margin-bottom: -1rem !important;
}

.ml-lt-n1,
.mx-lt-n1 {
	margin-left: -1rem !important;
}

.m-lt-n2 {
	margin: -2rem !important;
}

.mt-lt-n2,
.my-lt-n2 {
	margin-top: -2rem !important;
}

.mr-lt-n2,
.mx-lt-n2 {
	margin-right: -2rem !important;
}

.mb-lt-n2,
.my-lt-n2 {
	margin-bottom: -2rem !important;
}

.ml-lt-n2,
.mx-lt-n2 {
	margin-left: -2rem !important;
}

.m-lt-n3 {
	margin: -3rem !important;
}

.mt-lt-n3,
.my-lt-n3 {
	margin-top: -3rem !important;
}

.mr-lt-n3,
.mx-lt-n3 {
	margin-right: -3rem !important;
}

.mb-lt-n3,
.my-lt-n3 {
	margin-bottom: -3rem !important;
}

.ml-lt-n3,
.mx-lt-n3 {
	margin-left: -3rem !important;
}

.m-lt-n4 {
	margin: -4rem !important;
}

.mt-lt-n4,
.my-lt-n4 {
	margin-top: -4rem !important;
}

.mr-lt-n4,
.mx-lt-n4 {
	margin-right: -4rem !important;
}

.mb-lt-n4,
.my-lt-n4 {
	margin-bottom: -4rem !important;
}

.ml-lt-n4,
.mx-lt-n4 {
	margin-left: -4rem !important;
}

.m-lt-n5 {
	margin: -5rem !important;
}

.mt-lt-n5,
.my-lt-n5 {
	margin-top: -5rem !important;
}

.mr-lt-n5,
.mx-lt-n5 {
	margin-right: -5rem !important;
}

.mb-lt-n5,
.my-lt-n5 {
	margin-bottom: -5rem !important;
}

.ml-lt-n5,
.mx-lt-n5 {
	margin-left: -5rem !important;
}

.m-lt-n6 {
	margin: -6rem !important;
}

.mt-lt-n6,
.my-lt-n6 {
	margin-top: -6rem !important;
}

.mr-lt-n6,
.mx-lt-n6 {
	margin-right: -6rem !important;
}

.mb-lt-n6,
.my-lt-n6 {
	margin-bottom: -6rem !important;
}

.ml-lt-n6,
.mx-lt-n6 {
	margin-left: -6rem !important;
}

.m-lt-auto {
	margin: auto !important;
}

.mt-lt-auto,
.my-lt-auto {
	margin-top: auto !important;
}

.mr-lt-auto,
.mx-lt-auto {
	margin-right: auto !important;
}

.mb-lt-auto,
.my-lt-auto {
	margin-bottom: auto !important;
}

.ml-lt-auto,
.mx-lt-auto {
	margin-left: auto !important;
}

.text-lt-left {
	text-align: left !important;
}

.text-lt-right {
	text-align: right !important;
}

.text-lt-center {
	text-align: center !important;
}

.container,
.bg,
.map-panel-container {
	padding: 0 80px;
}

.section_bg-half {
	padding-top: 75px;
	padding-bottom: 40px;
	background: transparent;
}

.section-bg-left {
	margin-bottom: 0;
}

.section-bg-left::before {
	content: '';
	display: block;
	position: absolute;
	top: -52px;
	left: -5000px;
	right: 0;
	bottom: -40px;
	background: #F5F5F5;
}

.section-bg-left_lg::before {
	right: 40px;
}

.header__item:last-child {
	display: none;
}

.menu {
	display: block;
}

.banner__inner {
	padding-top: 10px;
	padding-right: 45%;
}

.map-panel {
	left: 80px;
}

.hamburger {
	display: none;
}

.navigation_active {
	transform: translateX(100%);
	visibility: hidden;
}

.navigation-blackout {
	display: none !important;
}

}

@media (min-width: 1400px) {

.col-dt {
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
}

.row-cols-dt-1 > * {
	flex: 0 0 100%;
	max-width: 100%;
}

.row-cols-dt-2 > * {
	flex: 0 0 50%;
	max-width: 50%;
}

.row-cols-dt-3 > * {
	flex: 0 0 33.33333%;
	max-width: 33.33333%;
}

.row-cols-dt-4 > * {
	flex: 0 0 25%;
	max-width: 25%;
}

.row-cols-dt-5 > * {
	flex: 0 0 20%;
	max-width: 20%;
}

.row-cols-dt-6 > * {
	flex: 0 0 16.66667%;
	max-width: 16.66667%;
}

.col-dt-auto {
	flex: 0 0 auto;
	width: auto;
	max-width: 100%;
}

.col-dt-1 {
	flex: 0 0 8.33333%;
	max-width: 8.33333%;
}

.col-dt-2 {
	flex: 0 0 16.66667%;
	max-width: 16.66667%;
}

.col-dt-3 {
	flex: 0 0 25%;
	max-width: 25%;
}

.col-dt-4 {
	flex: 0 0 33.33333%;
	max-width: 33.33333%;
}

.col-dt-5 {
	flex: 0 0 41.66667%;
	max-width: 41.66667%;
}

.col-dt-6 {
	flex: 0 0 50%;
	max-width: 50%;
}

.col-dt-7 {
	flex: 0 0 58.33333%;
	max-width: 58.33333%;
}

.col-dt-8 {
	flex: 0 0 66.66667%;
	max-width: 66.66667%;
}

.col-dt-9 {
	flex: 0 0 75%;
	max-width: 75%;
}

.col-dt-10 {
	flex: 0 0 83.33333%;
	max-width: 83.33333%;
}

.col-dt-11 {
	flex: 0 0 91.66667%;
	max-width: 91.66667%;
}

.col-dt-12 {
	flex: 0 0 100%;
	max-width: 100%;
}

.order-dt-first {
	order: -1;
}

.order-dt-last {
	order: 13;
}

.order-dt-0 {
	order: 0;
}

.order-dt-1 {
	order: 1;
}

.order-dt-2 {
	order: 2;
}

.order-dt-3 {
	order: 3;
}

.order-dt-4 {
	order: 4;
}

.order-dt-5 {
	order: 5;
}

.order-dt-6 {
	order: 6;
}

.order-dt-7 {
	order: 7;
}

.order-dt-8 {
	order: 8;
}

.order-dt-9 {
	order: 9;
}

.order-dt-10 {
	order: 10;
}

.order-dt-11 {
	order: 11;
}

.order-dt-12 {
	order: 12;
}

.offset-dt-0 {
	margin-left: 0;
}

.offset-dt-1 {
	margin-left: 8.33333%;
}

.offset-dt-2 {
	margin-left: 16.66667%;
}

.offset-dt-3 {
	margin-left: 25%;
}

.offset-dt-4 {
	margin-left: 33.33333%;
}

.offset-dt-5 {
	margin-left: 41.66667%;
}

.offset-dt-6 {
	margin-left: 50%;
}

.offset-dt-7 {
	margin-left: 58.33333%;
}

.offset-dt-8 {
	margin-left: 66.66667%;
}

.offset-dt-9 {
	margin-left: 75%;
}

.offset-dt-10 {
	margin-left: 83.33333%;
}

.offset-dt-11 {
	margin-left: 91.66667%;
}

.d-dt-none {
	display: none !important;
}

.d-dt-inline {
	display: inline !important;
}

.d-dt-inline-block {
	display: inline-block !important;
}

.d-dt-block {
	display: block !important;
}

.d-dt-table {
	display: table !important;
}

.d-dt-table-row {
	display: table-row !important;
}

.d-dt-table-cell {
	display: table-cell !important;
}

.d-dt-flex {
	display: flex !important;
}

.d-dt-inline-flex {
	display: inline-flex !important;
}

.flex-dt-row {
	flex-direction: row !important;
}

.flex-dt-column {
	flex-direction: column !important;
}

.flex-dt-row-reverse {
	flex-direction: row-reverse !important;
}

.flex-dt-column-reverse {
	flex-direction: column-reverse !important;
}

.flex-dt-wrap {
	flex-wrap: wrap !important;
}

.flex-dt-nowrap {
	flex-wrap: nowrap !important;
}

.flex-dt-wrap-reverse {
	flex-wrap: wrap-reverse !important;
}

.flex-dt-fill {
	flex: 1 1 auto !important;
}

.flex-dt-grow-0 {
	flex-grow: 0 !important;
}

.flex-dt-grow-1 {
	flex-grow: 1 !important;
}

.flex-dt-shrink-0 {
	flex-shrink: 0 !important;
}

.flex-dt-shrink-1 {
	flex-shrink: 1 !important;
}

.justify-content-dt-start {
	justify-content: flex-start !important;
}

.justify-content-dt-end {
	justify-content: flex-end !important;
}

.justify-content-dt-center {
	justify-content: center !important;
}

.justify-content-dt-between {
	justify-content: space-between !important;
}

.justify-content-dt-around {
	justify-content: space-around !important;
}

.align-items-dt-start {
	align-items: flex-start !important;
}

.align-items-dt-end {
	align-items: flex-end !important;
}

.align-items-dt-center {
	align-items: center !important;
}

.align-items-dt-baseline {
	align-items: baseline !important;
}

.align-items-dt-stretch {
	align-items: stretch !important;
}

.align-content-dt-start {
	align-content: flex-start !important;
}

.align-content-dt-end {
	align-content: flex-end !important;
}

.align-content-dt-center {
	align-content: center !important;
}

.align-content-dt-between {
	align-content: space-between !important;
}

.align-content-dt-around {
	align-content: space-around !important;
}

.align-content-dt-stretch {
	align-content: stretch !important;
}

.align-self-dt-auto {
	align-self: auto !important;
}

.align-self-dt-start {
	align-self: flex-start !important;
}

.align-self-dt-end {
	align-self: flex-end !important;
}

.align-self-dt-center {
	align-self: center !important;
}

.align-self-dt-baseline {
	align-self: baseline !important;
}

.align-self-dt-stretch {
	align-self: stretch !important;
}

.float-dt-left {
	float: left !important;
}

.float-dt-right {
	float: right !important;
}

.float-dt-none {
	float: none !important;
}

.m-dt-0 {
	margin: 0 !important;
}

.mt-dt-0,
.my-dt-0 {
	margin-top: 0 !important;
}

.mr-dt-0,
.mx-dt-0 {
	margin-right: 0 !important;
}

.mb-dt-0,
.my-dt-0 {
	margin-bottom: 0 !important;
}

.ml-dt-0,
.mx-dt-0 {
	margin-left: 0 !important;
}

.m-dt-1 {
	margin: 1rem !important;
}

.mt-dt-1,
.my-dt-1 {
	margin-top: 1rem !important;
}

.mr-dt-1,
.mx-dt-1 {
	margin-right: 1rem !important;
}

.mb-dt-1,
.my-dt-1 {
	margin-bottom: 1rem !important;
}

.ml-dt-1,
.mx-dt-1 {
	margin-left: 1rem !important;
}

.m-dt-2 {
	margin: 2rem !important;
}

.mt-dt-2,
.my-dt-2 {
	margin-top: 2rem !important;
}

.mr-dt-2,
.mx-dt-2 {
	margin-right: 2rem !important;
}

.mb-dt-2,
.my-dt-2 {
	margin-bottom: 2rem !important;
}

.ml-dt-2,
.mx-dt-2 {
	margin-left: 2rem !important;
}

.m-dt-3 {
	margin: 3rem !important;
}

.mt-dt-3,
.my-dt-3 {
	margin-top: 3rem !important;
}

.mr-dt-3,
.mx-dt-3 {
	margin-right: 3rem !important;
}

.mb-dt-3,
.my-dt-3 {
	margin-bottom: 3rem !important;
}

.ml-dt-3,
.mx-dt-3 {
	margin-left: 3rem !important;
}

.m-dt-4 {
	margin: 4rem !important;
}

.mt-dt-4,
.my-dt-4 {
	margin-top: 4rem !important;
}

.mr-dt-4,
.mx-dt-4 {
	margin-right: 4rem !important;
}

.mb-dt-4,
.my-dt-4 {
	margin-bottom: 4rem !important;
}

.ml-dt-4,
.mx-dt-4 {
	margin-left: 4rem !important;
}

.m-dt-5 {
	margin: 5rem !important;
}

.mt-dt-5,
.my-dt-5 {
	margin-top: 5rem !important;
}

.mr-dt-5,
.mx-dt-5 {
	margin-right: 5rem !important;
}

.mb-dt-5,
.my-dt-5 {
	margin-bottom: 5rem !important;
}

.ml-dt-5,
.mx-dt-5 {
	margin-left: 5rem !important;
}

.m-dt-6 {
	margin: 6rem !important;
}

.mt-dt-6,
.my-dt-6 {
	margin-top: 6rem !important;
}

.mr-dt-6,
.mx-dt-6 {
	margin-right: 6rem !important;
}

.mb-dt-6,
.my-dt-6 {
	margin-bottom: 6rem !important;
}

.ml-dt-6,
.mx-dt-6 {
	margin-left: 6rem !important;
}

.p-dt-0 {
	padding: 0 !important;
}

.pt-dt-0,
.py-dt-0 {
	padding-top: 0 !important;
}

.pr-dt-0,
.px-dt-0 {
	padding-right: 0 !important;
}

.pb-dt-0,
.py-dt-0 {
	padding-bottom: 0 !important;
}

.pl-dt-0,
.px-dt-0 {
	padding-left: 0 !important;
}

.p-dt-1 {
	padding: 1rem !important;
}

.pt-dt-1,
.py-dt-1 {
	padding-top: 1rem !important;
}

.pr-dt-1,
.px-dt-1 {
	padding-right: 1rem !important;
}

.pb-dt-1,
.py-dt-1 {
	padding-bottom: 1rem !important;
}

.pl-dt-1,
.px-dt-1 {
	padding-left: 1rem !important;
}

.p-dt-2 {
	padding: 2rem !important;
}

.pt-dt-2,
.py-dt-2 {
	padding-top: 2rem !important;
}

.pr-dt-2,
.px-dt-2 {
	padding-right: 2rem !important;
}

.pb-dt-2,
.py-dt-2 {
	padding-bottom: 2rem !important;
}

.pl-dt-2,
.px-dt-2 {
	padding-left: 2rem !important;
}

.p-dt-3 {
	padding: 3rem !important;
}

.pt-dt-3,
.py-dt-3 {
	padding-top: 3rem !important;
}

.pr-dt-3,
.px-dt-3 {
	padding-right: 3rem !important;
}

.pb-dt-3,
.py-dt-3 {
	padding-bottom: 3rem !important;
}

.pl-dt-3,
.px-dt-3 {
	padding-left: 3rem !important;
}

.p-dt-4 {
	padding: 4rem !important;
}

.pt-dt-4,
.py-dt-4 {
	padding-top: 4rem !important;
}

.pr-dt-4,
.px-dt-4 {
	padding-right: 4rem !important;
}

.pb-dt-4,
.py-dt-4 {
	padding-bottom: 4rem !important;
}

.pl-dt-4,
.px-dt-4 {
	padding-left: 4rem !important;
}

.p-dt-5 {
	padding: 5rem !important;
}

.pt-dt-5,
.py-dt-5 {
	padding-top: 5rem !important;
}

.pr-dt-5,
.px-dt-5 {
	padding-right: 5rem !important;
}

.pb-dt-5,
.py-dt-5 {
	padding-bottom: 5rem !important;
}

.pl-dt-5,
.px-dt-5 {
	padding-left: 5rem !important;
}

.p-dt-6 {
	padding: 6rem !important;
}

.pt-dt-6,
.py-dt-6 {
	padding-top: 6rem !important;
}

.pr-dt-6,
.px-dt-6 {
	padding-right: 6rem !important;
}

.pb-dt-6,
.py-dt-6 {
	padding-bottom: 6rem !important;
}

.pl-dt-6,
.px-dt-6 {
	padding-left: 6rem !important;
}

.m-dt-n1 {
	margin: -1rem !important;
}

.mt-dt-n1,
.my-dt-n1 {
	margin-top: -1rem !important;
}

.mr-dt-n1,
.mx-dt-n1 {
	margin-right: -1rem !important;
}

.mb-dt-n1,
.my-dt-n1 {
	margin-bottom: -1rem !important;
}

.ml-dt-n1,
.mx-dt-n1 {
	margin-left: -1rem !important;
}

.m-dt-n2 {
	margin: -2rem !important;
}

.mt-dt-n2,
.my-dt-n2 {
	margin-top: -2rem !important;
}

.mr-dt-n2,
.mx-dt-n2 {
	margin-right: -2rem !important;
}

.mb-dt-n2,
.my-dt-n2 {
	margin-bottom: -2rem !important;
}

.ml-dt-n2,
.mx-dt-n2 {
	margin-left: -2rem !important;
}

.m-dt-n3 {
	margin: -3rem !important;
}

.mt-dt-n3,
.my-dt-n3 {
	margin-top: -3rem !important;
}

.mr-dt-n3,
.mx-dt-n3 {
	margin-right: -3rem !important;
}

.mb-dt-n3,
.my-dt-n3 {
	margin-bottom: -3rem !important;
}

.ml-dt-n3,
.mx-dt-n3 {
	margin-left: -3rem !important;
}

.m-dt-n4 {
	margin: -4rem !important;
}

.mt-dt-n4,
.my-dt-n4 {
	margin-top: -4rem !important;
}

.mr-dt-n4,
.mx-dt-n4 {
	margin-right: -4rem !important;
}

.mb-dt-n4,
.my-dt-n4 {
	margin-bottom: -4rem !important;
}

.ml-dt-n4,
.mx-dt-n4 {
	margin-left: -4rem !important;
}

.m-dt-n5 {
	margin: -5rem !important;
}

.mt-dt-n5,
.my-dt-n5 {
	margin-top: -5rem !important;
}

.mr-dt-n5,
.mx-dt-n5 {
	margin-right: -5rem !important;
}

.mb-dt-n5,
.my-dt-n5 {
	margin-bottom: -5rem !important;
}

.ml-dt-n5,
.mx-dt-n5 {
	margin-left: -5rem !important;
}

.m-dt-n6 {
	margin: -6rem !important;
}

.mt-dt-n6,
.my-dt-n6 {
	margin-top: -6rem !important;
}

.mr-dt-n6,
.mx-dt-n6 {
	margin-right: -6rem !important;
}

.mb-dt-n6,
.my-dt-n6 {
	margin-bottom: -6rem !important;
}

.ml-dt-n6,
.mx-dt-n6 {
	margin-left: -6rem !important;
}

.m-dt-auto {
	margin: auto !important;
}

.mt-dt-auto,
.my-dt-auto {
	margin-top: auto !important;
}

.mr-dt-auto,
.mx-dt-auto {
	margin-right: auto !important;
}

.mb-dt-auto,
.my-dt-auto {
	margin-bottom: auto !important;
}

.ml-dt-auto,
.mx-dt-auto {
	margin-left: auto !important;
}

.text-dt-left {
	text-align: left !important;
}

.text-dt-right {
	text-align: right !important;
}

.text-dt-center {
	text-align: center !important;
}

}

@media (max-width: 991.98px) {

.section--tokens .col-pic {
	flex: 0 0 100%;
	max-width: 100%;
	padding-right: 10px;
}

.section--tokens .col-text {
	flex: 0 0 100%;
	max-width: 100%;
}

.section--btn {
	padding-top: 35px;
	padding-bottom: 0;
}

h1,
.h1 {
	font-size: 48px;
}

h2,
.h2 {
	font-size: 35px;
}

.btn {
	font-size: 12px;
}

.bg {
	display: none;
}

.profit__col--left {
	flex: 0 0 100%;
	max-width: 555px;
	padding-right: 10px;
	margin-top: 0;
	margin-bottom: 30px;
}

.profit__col--right {
	flex: 0 0 100%;
	max-width: 595px;
}

.form-section .form {
	max-width: 720px;
}

}

@media (max-width: 767.98px) {

.section {
	padding-top: 45px;
	padding-bottom: 45px;
}

.section--btn {
	padding-top: 35px;
	padding-bottom: 0;
}

h1,
.h1 {
	font-size: 35px;
}

h2,
.h2 {
	font-size: 28px;
}

h3,
.h3 {
	font-size: 20px;
}

h4,
.h4 {
	font-size: 18px;
}

h5,
.h5 {
	font-size: 18px;
}

.profit__pic {
	display: none;
}

.profit__list {
	position: relative;
	left: auto;
	right: auto;
	bottom: auto;
}

.form-section .btn {
	width: 100%;
}

.step {
	display: block;
	padding-bottom: 10px;
}

.step:before,
.step:after {
	display: none;
}

.step__num {
	margin-bottom: 15px;
}

.map-panel {
	padding-bottom: 30px;
}

.map-panel .contact {
	flex: 0 0 100%;
	max-width: 100%;
}

.map-panel .contact:last-child {
	margin-bottom: 0;
}

}

@media (max-width: 575.98px) {

.banner .h1 {
	font-size: 30px;
}

.profit__list ol {
	font-size: 16px;
}

.profit-item__text {
	font-size: 16px;
}

}

@media print {

.d-print-none {
	display: none !important;
}

.d-print-inline {
	display: inline !important;
}

.d-print-inline-block {
	display: inline-block !important;
}

.d-print-block {
	display: block !important;
}

.d-print-table {
	display: table !important;
}

.d-print-table-row {
	display: table-row !important;
}

.d-print-table-cell {
	display: table-cell !important;
}

.d-print-flex {
	display: flex !important;
}

.d-print-inline-flex {
	display: inline-flex !important;
}

}

